import React, { useEffect, useState } from "react";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

import { Loader } from "../../common/loader";
import { getObjLength } from "../../helpers/utils";
import PlainAlertMessage from "../../common/PlainAlertMessage";
import { propertyListAction } from "../../../redux/slices/admin/admin-dashboard/propertyListSlice";

const UpdateClosingByAdmin = ({
  handleClose = () => {},
  selectedProperty = {},
  callListAPI = () => {},
}) => {
  const [openPopup, SetOpenPopup] = useState(false);

  const dispatch = useDispatch();

  const { internalAdminDetails } = useSelector((state) => state?.propertyUsers);

  const { closingAdminDetails, closingAdminLoader } = useSelector(
    (state) => state?.propertyLists
  );

  const dropdownListData = [
    { key: 1, id: 1, full_name: "Super Admin" },
    {
      key: 2,
      id: selectedProperty?.owner_id,
      full_name: selectedProperty?.owner?.[0]?.full_name,
      isOwner: true,
    },
    ...internalAdminDetails?.data,
  ];
  console.log("ownerData", dropdownListData);

  const handleInternalAdminChange = (event, newValue, setFieldValue) => {
    const selectedSubadmin = dropdownListData?.find(
      (subadmin) => subadmin.full_name === newValue?.full_name
    );

    if (getObjLength(selectedSubadmin)) {
      setFieldValue("admin_id", selectedSubadmin.id);
      setFieldValue("internal_admin", selectedSubadmin.full_name);
    }
  };

  const onSubmit = (values) => {
    console.log("form values ==>", values);

    dispatch(
      propertyListAction.getClosingAdminDetails({
        url: `admin/edit-closed-by-user/${selectedProperty?.id}`,
        data: { closing_by: values?.admin_id },
        token: true,
      })
    );
  };

  const onPopupClose = (type) => {
    SetOpenPopup(false);
    dispatch(propertyListAction.fetchClosingAdminDetails({}));
    if (type !== "error") {
      callListAPI();
      handleClose();
    }
  };

  useEffect(() => {
    console.log("closingAdminDetails==>", closingAdminDetails);
    if (getObjLength(closingAdminDetails)) {
      SetOpenPopup(true);
    }
  }, [closingAdminDetails]);
  return (
    <>
      {!closingAdminDetails?.success && !closingAdminDetails?.error ? (
        <>
          <Button
            onClick={() => handleClose()}
            className="modal-close-btn"
            style={{
              position: "absolute",
              right: 10,
              top: 15,
              background: "transparent",
            }}
          >
            <CloseIcon sx={{ color: "#9CB8A6" }} />
          </Button>
          <Box className="accountBoxWrapper">
            <Typography align="center" variant="h3" className="pb-2">
              {getObjLength(selectedProperty?.closing_by) ? "Update" : "Add"}{" "}
              Closing By
            </Typography>
            <Formik
              enableReinitialize
              validateOnChange
              initialValues={{
                internal_admin:
                  (getObjLength(selectedProperty?.closing_by)
                    ? selectedProperty?.closing_by?.full_name
                    : selectedProperty?.owner?.[0]?.full_name) || "",
                admin_id: "",
              }}
              validationSchema={Yup.object().shape({
                internal_admin: Yup.string(
                  "Please Select any Admin Name."
                ).required("Admin name is required"),
              })}
              onSubmit={(values) => onSubmit(values)}
            >
              {({ values, setFieldValue, touched, errors, initialValues }) => {
                return (
                  <Form>
                    <Grid container className="filter-options-box">
                      <Grid item xs={12} sm={12}>
                        <FormControl
                          fullWidth
                          className="sign-textField "
                          error={
                            touched.internal_admin &&
                            Boolean(errors.internal_admin)
                          }
                        >
                          <Autocomplete
                            className={`${
                              touched.internal_admin &&
                              Boolean(errors.internal_admin)
                                ? "autocomplete_error"
                                : ""
                            }`}
                            id="asynchronous-demo"
                            value={
                              dropdownListData.find(
                                (option) =>
                                  option?.full_name === values?.internal_admin
                              ) || null
                            } // Ensure the value matches the option structure
                            disableClearable
                            options={dropdownListData || []} // Provide a fallback for empty options
                            getOptionLabel={(option) => option?.full_name || ""} // Use full_name as the label
                            isOptionEqualToValue={(option, value) =>
                              option?.full_name === value?.full_name
                            } // Custom comparison
                            onChange={(e, newValue) =>
                              handleInternalAdminChange(
                                e,
                                newValue,
                                setFieldValue
                              )
                            }
                            renderOption={(props, option) => (
                              <>
                                <li {...props} key={option?.id}>
                                  {option?.full_name}
                                </li>
                                {/* {option?.key === 2 && <Divider>Admins</Divider>} */}
                              </>
                            )}
                            renderInput={(params) => (
                              <TextField {...params} label="Admin List" />
                            )}
                          />

                          {touched.internal_admin &&
                            Boolean(errors.internal_admin) && (
                              <FormHelperText sx={{ color: "#d32f2f" }}>
                                {touched.internal_admin &&
                                  errors.internal_admin}
                              </FormHelperText>
                            )}
                        </FormControl>
                      </Grid>
                    </Grid>
                    <div className="text-center stepBtn mt-3">
                      <Button
                        className="popup_Btn other_popup_btn mx-2"
                        type="submit"
                        variant="contained"
                        onClick={() => handleClose()}
                      >
                        Cancel
                      </Button>

                      <Button
                        type="submit"
                        disabled={
                          JSON.stringify(initialValues) ===
                          JSON.stringify(values)
                        }
                        className={`popup_Btn success_popup_btn  ${
                          JSON.stringify(initialValues) ===
                            JSON.stringify(values) && "cursor-disable"
                        }`}
                        variant="contained"
                      >
                        {getObjLength(selectedProperty?.closing_by)
                          ? "Update"
                          : "Add"}
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </>
      ) : null}

      {openPopup && getObjLength(closingAdminDetails) && (
        <>
          {closingAdminDetails?.success ? (
            <PlainAlertMessage
              type="Success"
              openPopup={openPopup}
              onClose={true}
              onCloseFun={() => onPopupClose("success")}
              message={closingAdminDetails?.message}
              footer={false}
            />
          ) : (
            <PlainAlertMessage
              type="Error"
              openPopup={openPopup}
              onClose={true}
              onCloseFun={() => onPopupClose("error")}
              message={closingAdminDetails?.data?.message}
              footer={false}
            />
          )}
        </>
      )}
      <Loader open={closingAdminLoader} />
    </>
  );
};

export default UpdateClosingByAdmin;
