import React, { useEffect, useState } from "react";

import * as Yup from "yup";
import { Form, Formik } from "formik";

import CloseIcon from "@mui/icons-material/Close";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  Stack,
  Typography,
} from "@mui/material";

import { Loader } from "../../common/loader";
import FileUpload from "../../common/FileUpload";
import AlertDialog from "../../common/AlertDialog";
import CustomTextField from "../../common/CustomTextField";
import CustomLinkWrapper from "../../common/CustomLinkWrapper";
import {
  createFileUrl,
  getDateInYYYYMMDDFormat,
  getObjLength,
} from "../../helpers/utils";
import { propertyListAction } from "../../../redux/slices/admin/admin-dashboard/propertyListSlice";
import { useDispatch } from "react-redux";
import PlainAlertMessage from "../../common/PlainAlertMessage";

const UploadRequestDocModal = ({
  open,
  isPropertyVerified = true,
  selectedData = {},
  handleClose,
  isUploadSuccess,
  successMessage,
  errorMessage,
  uploadLoader,
  handleUploadDoc,
  fetchPropertyListingData,
  caption = null,
  from = null,
  fileFormates = {},
  uploadedDocData = {},
  multiple = true,
  showUCCFields = false,
}) => {
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState([]);
  const [isResponseModalOpen, setIsResponseModalOpen] = useState(false);

  const handleCloseModal = () => {
    setSelectedFile([]);
    handleClose();
  };

  const handleOpenResponseModal = () => {
    setIsResponseModalOpen(true);
  };
  const handleCloseResponseModal = () => {
    // clear success or error message response
    propertyListAction.fetchUploadedPropertyDocuments({});
    setIsResponseModalOpen(false);
  };

  useEffect(() => {
    if (getObjLength(uploadedDocData)) {
      handleOpenResponseModal();
    }
  }, [uploadedDocData]);
  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        onClose={handleCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {(!isResponseModalOpen || !getObjLength(uploadedDocData)) &&
          (isPropertyVerified ? (
            <>
              <DialogTitle id="alert-dialog-title">
                {getObjLength(selectedData)
                  ? "Replace UCC Document"
                  : "Upload Document"}
                {/* {!isUploadSuccess && !errorMessage && ( */}
                <Typography pb={2}>
                  {caption
                    ? caption
                    : `Please upload ${
                        showUCCFields
                          ? "verified ucc document"
                          : "general documents "
                      }   for this property.`}
                </Typography>
                {/* )} */}
                <Button
                  onClick={() => handleCloseModal()}
                  className="modal-close-btn"
                  style={{
                    position: "absolute",
                    right: 10,
                    top: 15,
                    background: "transparent",
                  }}
                >
                  <CloseIcon sx={{ color: "#9CB8A6" }} />
                </Button>
              </DialogTitle>
              {/* <DialogContent className="bg-white"> */}

              <>
                <Box className="d-flex justify-content-center bg-white">
                  <Formik
                    enableReinitialize
                    validateOnChange
                    initialValues={{
                      upload_doc: [],
                      recording_date: getObjLength(selectedData)
                        ? getDateInYYYYMMDDFormat(
                            new Date(selectedData?.recording_date)
                          )
                        : getDateInYYYYMMDDFormat(new Date()) || "",
                      instrument_number: getObjLength(selectedData)
                        ? selectedData?.instrument_number
                        : "",
                      booking_page: getObjLength(selectedData)
                        ? selectedData?.booking_page
                        : "",
                    }}
                    validationSchema={Yup.object().shape(
                      {
                        upload_doc: multiple
                          ? Yup.array()
                              .min(1, "Please Select at least 1 Document")
                              .required("Document is required")
                          : Yup.string().required("Document is required"),
                        recording_date: Yup.string(
                          "Please enter recording date."
                        )
                          .trim()
                          .required("Please enter recording date."),
                        booking_page: Yup.string(
                          "Please enter booking page"
                        ).when("instrument_number", {
                          is: (a) => !a && showUCCFields,
                          then: Yup.string("Please enter booking page.")
                            .trim()
                            .required(
                              "Please enter either booking page or instrument number."
                            ),
                          otherwise: Yup.string("").optional().nullable(),
                        }),
                        instrument_number: Yup.string(
                          "Please enter instrument number"
                        ).when("booking_page", {
                          is: (a) => !a && showUCCFields,
                          then: Yup.string("Please enter booking page.")
                            .trim()
                            .required(
                              "Please enter either booking page or instrument number."
                            ),
                          otherwise: Yup.string("").optional().nullable(),
                        }),
                      },
                      [["booking_page", "instrument_number"]]
                    )}
                    onSubmit={handleUploadDoc}
                  >
                    {({ values, setFieldValue, touched, errors }) => {
                      return (
                        <Form className="w-100">
                          <DialogContent dividers className="bg-white">
                            {!isUploadSuccess && (
                              <>
                                <Grid container spacing={2.5}>
                                  <Grid item xs={12} md={12}>
                                    <FileUpload
                                      name="upload_doc"
                                      accept={
                                        from === "TerminationDocument"
                                          ? fileFormates
                                          : {
                                              "image/png": [
                                                ".png",
                                                ".jpeg",
                                                ".jpg",
                                              ],
                                              "application/vnd.ms-powerpoint": [
                                                ".ppt",
                                              ],
                                              "application/pdf": [".pdf"],
                                              "audio/mpeg": [".mp3"],
                                            }
                                      }
                                      multiple={multiple}
                                      onFileUpload={async (file) => {
                                        if (!multiple) {
                                          setSelectedFile(file[0]);
                                          setFieldValue("upload_doc", file[0]);
                                        } else {
                                          const paths = new Set(
                                            selectedFile.map(({ path }) => path)
                                          );

                                          const finalArr = file.filter(
                                            ({ path }) => !paths.has(path)
                                          );

                                          setFieldValue(
                                            "upload_doc",
                                            selectedFile.concat(finalArr)
                                          );

                                          setSelectedFile((prevState) =>
                                            prevState.concat(finalArr)
                                          );
                                        }

                                        // const url = createFileUrl(file[0]);
                                        // document
                                        //   .getElementById("previewUrl")
                                        //   .setAttribute("src", url);
                                        // URL.revokeObjectURL(url);
                                      }}
                                    />
                                    <FormHelperText>
                                      {touched.upload_doc &&
                                        errors.upload_doc && (
                                          <Typography
                                            className="text-danger"
                                            sx={{ fontSize: "0.8rem" }}
                                          >
                                            {errors.upload_doc}
                                          </Typography>
                                        )}
                                    </FormHelperText>
                                  </Grid>

                                  {showUCCFields ? (
                                    <>
                                      {/* booking page */}
                                      <Grid item xs={12} md={6} px={1} pb={1}>
                                        <CustomTextField
                                          label="Booking Page"
                                          name="booking_page"
                                          validation={{
                                            values,
                                            setFieldValue,
                                            touched,
                                            errors,
                                          }}
                                          otherProps={{
                                            onChange: (event) => {
                                              setFieldValue(
                                                "instrument_number",
                                                ""
                                              );
                                              setFieldValue(
                                                "booking_page",
                                                event.target.value
                                              );
                                            },
                                          }}
                                        />
                                      </Grid>

                                      {/* instrument number */}
                                      <Grid item xs={12} md={6} px={1} pb={1}>
                                        <CustomTextField
                                          label="Instrument Number"
                                          name="instrument_number"
                                          validation={{
                                            values,
                                            setFieldValue,
                                            touched,
                                            errors,
                                          }}
                                          otherProps={{
                                            onChange: (event) => {
                                              setFieldValue("booking_page", "");
                                              setFieldValue(
                                                "instrument_number",
                                                event.target.value
                                              );
                                            },
                                          }}
                                        />
                                      </Grid>

                                      {/* recording date */}
                                      <Grid item xs={12} md={6} px={1} pb={1}>
                                        <CustomTextField
                                          label="Recording Date"
                                          name="recording_date"
                                          type="date"
                                          validation={{
                                            values,
                                            setFieldValue,
                                            touched,
                                            errors,
                                          }}
                                        />
                                      </Grid>
                                    </>
                                  ) : null}
                                </Grid>
                              </>
                            )}

                            {!isUploadSuccess ? (
                              <Box pt={2} className="dropzone-items">
                                {selectedFile?.length > 0
                                  ? selectedFile?.map((file) => (
                                      <Box
                                        className="preview-file bg-white"
                                        padding="3px 15px"
                                        mb={0.9}
                                        key={file?.path}
                                      >
                                        <CustomLinkWrapper
                                          link={
                                            file ? createFileUrl(file) : "#"
                                          }
                                          title={file?.path}
                                          key={file?.path}
                                          classes="preview-url"
                                        />
                                        <span
                                          className="cursor-pointer"
                                          onClick={() => {
                                            setFieldValue("upload_doc", [
                                              ...selectedFile.filter(
                                                (item) =>
                                                  item.path !== file?.path
                                              ),
                                            ]);

                                            setSelectedFile((prevState) =>
                                              prevState.filter(
                                                (item) =>
                                                  item.path !== file?.path
                                              )
                                            );
                                          }}
                                        >
                                          <CloseOutlinedIcon />
                                        </span>
                                      </Box>
                                    ))
                                  : getObjLength(selectedFile) && (
                                      <Box
                                        className="preview-file bg-white"
                                        padding="3px 15px"
                                        mb={0.9}
                                      >
                                        <CustomLinkWrapper
                                          link={
                                            selectedFile
                                              ? createFileUrl(selectedFile)
                                              : "#"
                                          }
                                          title={selectedFile?.path}
                                          key={selectedFile?.path}
                                          classes="preview-url"
                                        />
                                        <span
                                          className="cursor-pointer"
                                          onClick={() => {
                                            setFieldValue("upload_doc", "");

                                            setSelectedFile("");
                                          }}
                                        >
                                          <CloseOutlinedIcon />
                                        </span>
                                      </Box>
                                    )}
                              </Box>
                            ) : null}
                            {/* )} */}
                          </DialogContent>

                          <DialogActions className="upload-req-dialog-action">
                            <Stack direction="row" spacing={2} pb={2}>
                              {isUploadSuccess ? (
                                <Button
                                  className="popup_Btn success_popup_btn"
                                  onClick={() => {
                                    handleCloseModal();
                                  }}
                                >
                                  Close
                                </Button>
                              ) : (
                                <>
                                  <Button
                                    className="popup_Btn other_popup_btn mx-2"
                                    onClick={() => {
                                      handleCloseModal();
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    type="submit"
                                    className="popup_Btn success_popup_btn"
                                  >
                                    Upload
                                  </Button>
                                </>
                              )}
                            </Stack>
                          </DialogActions>
                        </Form>
                      );
                    }}
                  </Formik>
                </Box>
              </>
            </>
          ) : (
            <PlainAlertMessage
              type="Error"
              openPopup={open}
              onClose={true}
              onCloseFun={handleClose}
              message={
                <span className="px-5">
                  You cannot generate a UCC document because the required
                  security question was not answered.
                </span>
              }
              footer={false}
            />
          ))}

        {/* </DialogContent> */}

        {/* success or error message */}
        {isResponseModalOpen && getObjLength(uploadedDocData) && (
          <PlainAlertMessage
            type={isUploadSuccess ? "Success" : "Error"}
            openPopup={isResponseModalOpen}
            onClose={true}
            onCloseFun={() => {
              handleCloseResponseModal();
              handleCloseModal();
              dispatch(
                propertyListAction.fetchUploadedPropertyGeneralDocuments({})
              );
            }}
            message={
              isUploadSuccess
                ? successMessage
                : errorMessage || "Something went wrong"
            }
            footer={false}
            buttons={
              isUploadSuccess ? (
                <div className="text-center stepBtn mt-4">
                  <Button
                    onClick={() => {
                      handleCloseResponseModal();
                      handleCloseModal();
                      fetchPropertyListingData();
                      dispatch(
                        propertyListAction.fetchUploadedPropertyGeneralDocuments(
                          {}
                        )
                      );
                    }}
                    className="next_button"
                    variant="contained"
                  >
                    ok, All Good
                  </Button>
                </div>
              ) : (
                <div className="text-center stepBtn mt-4">
                  <Button
                    onClick={handleCloseResponseModal}
                    className=" error_popup_btn next_button"
                    variant="contained"
                  >
                    ok, Close
                  </Button>
                </div>
              )
            }
          />
        )}
        <Loader open={uploadLoader} />
      </Dialog>
    </>
  );
};

export default UploadRequestDocModal;
