import spouse from "./spouse/spouseSlice";
import lennarSignUp from "./lennar/signUp";
import admins from "./admin/admins/adminSlice";
import signupUser from "./user/userSignUpSlice";
import signInUser from "./user/userSignInSlice";
import userProfile from "./user/userProfileSlice";
import userSettings from "./user/userSettingSlice";
import logsLists from "./logsTable/logsTableSlice";
import subUsers from "./admin/subUsers/subUserSlice";
import properties from "./properties/propertiesSlice";
import addLeads from "./admin/addLeads/addLeadsSlice";
import documents from "./userDocuments/documentsSlice";
import tryLeadPage from "./tryDomain/tryLeadPageSlice";
import marketValue from "./market-value/marketValueSlice";
import userDetailsForm from "./user/userDetailsFormSlice";
import exportCSVDetails from "./export-csv/exportcsvSlice";
import subscription from "./subscription/subscriptionSlice";
import promocodes from "./admin/promocodes/promocodesSlice";
import userProfessional from "./user/userProfessionalSlice";
import lennarSubscription from "./lennar/subscriptionSlice";
import notifications from "./notification/notificationSlice";
import autocomplete from "./google-map-api/autocompleteSlice";
import serviceProviderSignup from "./serviceProvider/SPSignUpSlice";
import serviceProviderSignin from "./serviceProvider/SPSignINSlice";
import professionals from "./admin/professionals/professionalsSlice";
import propertyUsers from "./admin/propertyUsers/propertyUsersSlice";
import propertyLists from "./admin/admin-dashboard/propertyListSlice";
import otherProviders from "./admin/other-providers/otherProvidersSlice";
import adminDashboard from "./admin/admin-dashboard/adminDashboardSlice";
import countyRecorders from "./admin/county-recorder/countyRecorderSlice";
import seviceProviders from "./admin/serviceProviders/seviceProvidersSlice";
import requestDocuments from "./admin/requestDocuments/requestDocumentsSlice";
import sendInvitations from "./professional/send-invitation/sendInvitationSlice";
import servicerProfile from "./serviceProvider/servicerProfile/servicerProfileSlice";
import professionalUsersLists from "./professional/professional-users-list/usersListSlice";
import professionalPropertyLists from "./professional/professional-dashboard/propertyListSlice";
import propertyRiskManagement from "./admin/property-risk-management/propertyRiskManagementSlice";
import professionalProperties from "./professional/professional-properties/professionalPropertiesSlice";

const slices = {
  signUpUser: signupUser,
  signInUser: signInUser,
  userProfile: userProfile,
  properties: properties,
  autocomplete: autocomplete,
  marketValue: marketValue,
  subscription: subscription,
  propertyLists: propertyLists,
  documents: documents,
  professionals: professionals,
  promocodes: promocodes,
  notifications: notifications,
  professionalPropertyLists: professionalPropertyLists,
  addLeads: addLeads,
  professionalUsersLists: professionalUsersLists,
  userSettings: userSettings,
  propertyUsers: propertyUsers,
  serviceProviderSignup: serviceProviderSignup,
  serviceProviderSignin: serviceProviderSignin,
  seviceProviders: seviceProviders,
  admins: admins,
  sendInvitations: sendInvitations,
  spouse: spouse,
  userProfessional: userProfessional,
  servicerProfile: servicerProfile,
  requestDocuments: requestDocuments,
  logsLists: logsLists,
  subUsers: subUsers,
  professionalProperties: professionalProperties,
  tryLeadPage: tryLeadPage,
  lennarSubscription: lennarSubscription,
  otherProviders: otherProviders,
  propertyRiskManagement: propertyRiskManagement,
  lennarSignUp: lennarSignUp,
  countyRecorders: countyRecorders,
  exportCSVDetails: exportCSVDetails,
  userDetailsForm: userDetailsForm,
  adminDashboard: adminDashboard,
};
export default slices;
