import React, { useState } from "react";

const ReadMoreText = ({ children, readMoreClass }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <>
      <p className="text">{isReadMore ? text.slice(0, 110) : text}</p>
      <span
        onClick={(event) => {
          event.stopPropagation();
          toggleReadMore();
        }}
        className="read-or-hide"
      >
        <p className={`read_more ${readMoreClass}`}>
          {text.length > 110
            ? isReadMore
              ? " Read More >"
              : " Show less <<"
            : null}
        </p>
      </span>
    </>
  );
};
export default ReadMoreText;
