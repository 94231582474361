const setMeta = (title, description) => {
  // Helper function to set or create meta tags
  const updateMetaTag = (name, content) => {
    let metaTag = document.querySelector(`meta[name="${name}"]`);
    if (metaTag) {
      metaTag.setAttribute("content", content);
    } else {
      metaTag = document.createElement("meta");
      metaTag.name = name;
      metaTag.content = content;
      document.head.appendChild(metaTag);
    }
  };

  // Update title and description for all specified meta tags
  updateMetaTag("title", title);
  document.title = title;
  updateMetaTag("description", description);
  updateMetaTag("og:title", title);
  updateMetaTag("og:description", description);
  updateMetaTag("twitter:title", title);
  updateMetaTag("twitter:description", description);
};

export const setMetaDataForSEO = (pathname) => {
  switch (pathname) {
    case "/":
      // document.title = "Home Equity Protection | EquityProtect";
      setMeta(
        "Home Equity Protection | EquityProtect",
        "Protect your home equity with EquityProtect. Our solutions help secure your financial future by safeguarding your most valuable asset. Get expert guidance today."
      );
      break;
    case "/how-it-works":
      // document.title = "How Home Equity Protection Works | EquityProtect";
      setMeta(
        "How Home Equity Protection Works | EquityProtect",
        "Learn how EquityProtect secures your home equity with tailored protection plans. Understand the process and safeguard your financial future with trusted solutions."
      );
      break;
    case "/pricing":
      // document.title = "Home Equity Protection Pricing | EquityProtect";
      setMeta(
        "Home Equity Protection Pricing | EquityProtect",
        "Find competitive pricing for home equity protection plans at EquityProtect. Protect your home‚Äôs value with affordable solutions designed to secure your financial future."
      );
      break;
    case "/property-risk-management":
      // document.title = "Property Risk Management Services | EquityProtect";
      setMeta(
        "EquityProtect offers expert property risk management services to help you protect your home equity. Take control of your investments with our reliable risk management solutions."
      );
      break;
    case "/title-protection-real-estate-investors":
      // document.title = "Title Protection for Real Estate Investors | EquityProtect";
      setMeta(
        "Title Protection for Real Estate Investors | EquityProtect",
        "Are your real estate investments at risk? Learn how to protect your properties from title theft. Get a free risk assessment now."
      );
      break;
    case "/title-protection-long-time-homeowners":
      // document.title = "Title Protection for Long-Time Homeowners | EquityProtect";
      setMeta(
        "Home Title Protection for Long-Time Homeowners | EquityProtect",
        "Protect your home from title theft. Learn about the title theft risks long-time homeowners face in the digital age. Safeguard your title & equity with EquityProtect."
      );
      break;
    case "/what-is-home-title-theft":
      // document.title = "What is Home Title Theft? What is It & Ways to Protect Yourself | EquityProtect";
      setMeta(
        "What is Home Title Theft? What is It & Ways to Protect Yourself | EquityProtect",
        "Is your home at risk? Discover the growing threat of home title theft and how to safeguard your biggest investment with EquityProtect. Learn more."
      );
      break;

    case "/for-service-provider":
      // document.title = "Home Equity Service Providers | EquityProtect";
      setMeta(
        "Home Equity Service Providers | EquityProtect",
        "Partner with EquityProtect as a trusted service provider. Help clients safeguard their home equity with reliable protection plans tailored to meet individual needs."
      );
      break;

    case "/gallagher":
      setMeta(
        "Mike Gallagher Recommends EquityProtect | EquityProtect",
        "Discover if your property is at risk of title theft with the home equity protection solution recommended by Mike Gallagher. Get a free risk assessment now."
      );
      break;

    case "/real-estate-scams":
      setMeta(
        "Common Real Estate Scams &amp; How to Handle Them | EquityProtect",
        "Learn how to identify real estate red flags & common types of real estate fraud. Learn how to proactively protect your home title with EquityProtect."
      );
      break;

    case "/does-title-insurance-cover-title-theft":
      setMeta(
        "What Is Title Insurance & What Does It Cover? | EquityProtect",
        "EquityProtect talks title insurance: Coverage, types, & how it differs from title lock. Learn what title insurance covers & what it doesn't."
      );
      break;

    case "/can-your-home-title-be-stolen-if-you-have-a-mortgage":
      setMeta(
        "Can Your Home Title Be Stolen If You Have a Mortgage | EquityProtect",
        "Does a mortgage protect you from home title theft? Learn how EquityProtect prevents thieves from selling your house or property without your knowledge."
      );
      break;

    default:
      setMeta(
        "Home Lock - EquityProtect",
        "The First and Only True Home Title Lock - Prevents Home Stealing, Title Theft and Equity Protection"
      );
  }
};
