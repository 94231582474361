import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Box, Button, Fade, Paper, Popper, Typography } from "@mui/material";

import UserStates from "./UserStates";
import { Loader } from "../../../common/loader";
import DateFilterModal from "./DateFilterModal";
import { getObjLength } from "../../../helpers/utils";
import { adminDashboardAction } from "../../../../redux/slices/admin/admin-dashboard/adminDashboardSlice";

import "./UserStates.scss";

const DashboardStates = () => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dateRange, setDateRange] = useState();
  const [dateRangeFormat, setDateRangeFormat] = useState();
  const [dateOptions, setDateOptions] = useState("This Month");

  // store data
  const { adminDashboardDetails, adminDashboardLoader } = useSelector(
    (state) => state?.adminDashboard
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  useEffect(() => {
    dispatch(
      adminDashboardAction.getAdminDashboardData({
        url: "admin/admin-states",
        data: {},
        token: true,
      })
    );
  }, [dispatch]);

  return (
    <>
      {getObjLength(adminDashboardDetails) && adminDashboardDetails?.success ? (
        <>
          <Popper
            // sx={{ zIndex: 1200 }}
            open={open}
            anchorEl={anchorEl}
            placement={"bottom-end"}
            transition
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Box className="accountBoxWrapper date_filter_card">
                  <Typography align="center" variant="body1">
                    {/* We just need to gather some information to set up Export
                    payments details. */}
                  </Typography>
                  <DateFilterModal
                    closePopup={setOpen}
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    setDateRangeFormat={setDateRangeFormat}
                    dateRangeFormat={dateRangeFormat}
                    dateOptions={dateOptions}
                    setDateOptions={setDateOptions}
                  />
                </Box>
              </Fade>
            )}
          </Popper>
          <Paper
            elevation={0}
            className=" d-flex justify-content-between"
            sx={{ background: "none" }}
          >
            <Box
              // className="w-100"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                alignContent: "center",
                mb: 1,
              }}
            >
              <Typography variant="h6" className="card-title fw-600 px-2 pt-2">
                Dashboard States
              </Typography>
            </Box>
            <Box className="">
              <Button
                variant="contained"
                className="success-btn admin_buttons"
                style={{
                  borderRadius: "20px",
                }}
                onClick={handleClick}
              >
                Filter Datewise
              </Button>
            </Box>
          </Paper>
          <Box sx={{ width: "100%" }} className="mt-4">
            <Paper sx={{ width: "100%", mb: 2 }}>
              <UserStates
                statistics={adminDashboardDetails?.data}
                dateRange={dateRange}
                dateRangeFormat={dateRangeFormat}
              />
            </Paper>
          </Box>
        </>
      ) : (
        <p className="text-danger text-center">
          {adminDashboardDetails?.data?.message}
        </p>
      )}
      <Loader open={adminDashboardLoader} />
    </>
  );
};

export default DashboardStates;
