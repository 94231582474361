import React from "react";

import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";

import { Box, Button, Divider, Typography } from "@mui/material";

import maskgroup from "../../assets/properties/mask-group.webp";

import "./LearnEquityProtectSection.scss";
const LearnEquityProtectSection = () => {
  return (
    <section className="learn_ep_section">
      <Container fluid className="p-0 card_top box_shadow">
        <>
          <Row className="text-start">
            <Col xs={12} sm={12} md={6} className="">
              <Box className="card_content">
                <>
                  <Typography className="title mb-4 d-flex text-start">
                    Learn How EquityProtect™ Can Help Protect You
                  </Typography>
                  <ul
                    className="features_list mb-4"
                    style={{ listStyleType: "none", textAlign: "left" }}
                  >
                    <li className="position-relative mb-3">
                      Physical Protection prevents the crime from occurring
                    </li>
                    <li className="position-relative mb-3">
                      24/7 title monitoring
                    </li>
                    <li className="position-relative mb-3">
                      Utilization of Multifactor authentication before allowing
                      permission to move forward with a new refinance or sale
                    </li>
                  </ul>
                  <Link
                    className=" text-white text-decoration-none"
                    to="/create-account"
                  >
                    <Button className="button success_popup_btn">
                      Find Out More
                    </Button>
                  </Link>
                </>
              </Box>
            </Col>
            <Col xs={12} sm={12} md={6} className="">
              <Box className="m-0 markgroup_img">
                <img src={maskgroup} className="h-100 w-100" alt="home_Img" />
              </Box>
            </Col>
          </Row>
        </>
      </Container>
      <div className="bottom_seperator">
        <Divider />
      </div>
      <div className="bottom_button">
        <Link to="/blog">
          <Button className="">Back to all articles</Button>
        </Link>
      </div>
    </section>
  );
};

export default LearnEquityProtectSection;
