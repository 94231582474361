import { all } from "redux-saga/effects";

import { watchSpouseSagaAsync } from "./spouse/spouseSaga";
import { watchSignupUsersAsync } from "./user/userSignupSaga";
import { watchSignInUsersAsync } from "./user/userSignInSaga";
import { watchUserProfileAsync } from "./user/userProfileSaga";
import { watchAdminsSagaAsync } from "./admin/admins/adminsSaga";
import { watchLennarSignUpSagaAsync } from "./lennar/signUpSaga";
import { watchLogsListSagaAsync } from "./logsTable/logsTableSaga";
import { watchPropertiesSagaAsync } from "./properties/propertySaga";
import { watchUserSettingsSagaAsync } from "./user/userSettingsSaga";
import { watchAddLeadsSagaAsync } from "./admin/addLeads/addLeadSaga";
import { watchSubUsersSagaAsync } from "./admin/subUsers/subUsersSaga";
import { watchTryLeadPageSagaAsync } from "./tryDomain/tryLeadPageSaga";
import { watchExportCSVDetailsAsync } from "./export-csv/exportCsvSaga";
import { watchMarketvalueSagaAsync } from "./market-value/marketValueSaga";
import { watchUserDetailsFormSagaAsync } from "./user/userDetailsFormSaga";
import { watchUserDocumentsSagaAsync } from "./userDocuments/documentsSaga";
import { watchPromocodeSagaAsync } from "./admin/promocodes/promocodesSaga";
import { watchSubscriptionSagaAsync } from "./subscription/subscriptionSaga";
import { watchNotificationSagaAsync } from "./notification/notificationSaga";
import { watchUserProfessionalSagaAsync } from "./user/userProfessionalSaga";
import { watchLennarSubscriptionSagaAsync } from "./lennar/subscriptionSaga";
import { watchSignupServiceProviderAsync } from "./serviceProvider/SPSignupSaga";
import { watchSigninServiceProviderAsync } from "./serviceProvider/SPSigninSaga";
import { watchProfessionalSagaAsync } from "./admin/professionals/professionalsSaga";
import { watchPropertyUsersSagaAsync } from "./admin/propertyUsers/propertyUsersSaga";
import { watchOtherProvidersSagaAsync } from "./admin/otherProviders/otherProvidersSaga";
import { watchPropertyListsSagaAsync } from "./admin/admin-dashboard/propertyListingSaga";
import { watchAdminDashboardSagaAsync } from "./admin/admin-dashboard/adminDashboardSaga";
import { watchCountyRecordersSagaAsync } from "./admin/countyRecorder/countyRecordersSaga";
import { watchSeviceProvidersSagaAsync } from "./admin/seviceProviders/seviceProvidersSaga";
import { watchSendInvitationSagaAsync } from "./professional/send-invitation/sendInvitationSaga";
import { watchRequestDocumentsSagaAsync } from "./admin/requestDocumentList/requestDocumentListSaga";
import { watchRiskManagementSagaAsync } from "./admin/property-risk-management/propertyRiskManagementSaga";
import { watchServiceProviderProfileSagaAsync } from "./serviceProvider/servicerProfile/servicerProfileSaga";
import { watchProfessionalUsersListsSagaAsync } from "./professional/professional-users-list/usersListingSaga";
import { watchProfessionalPropertyListsSagaAsync } from "./professional/professional-dashboard/propertyListingSaga";
import { watchProfessionalPropertiesSagaAsync } from "./professional/professional-properties/professionalPropertiesSaga";

export function* rootSaga() {
  yield all([
    watchSignupUsersAsync(),
    watchSignInUsersAsync(),
    watchUserProfileAsync(),
    watchPropertiesSagaAsync(),
    watchMarketvalueSagaAsync(),
    watchSubscriptionSagaAsync(),
    watchPropertyListsSagaAsync(),
    watchUserDocumentsSagaAsync(),
    watchProfessionalSagaAsync(),
    watchPromocodeSagaAsync(),
    watchNotificationSagaAsync(),
    watchProfessionalPropertyListsSagaAsync(),
    watchAddLeadsSagaAsync(),
    watchProfessionalUsersListsSagaAsync(),
    watchUserSettingsSagaAsync(),
    watchPropertyUsersSagaAsync(),
    watchSignupServiceProviderAsync(),
    watchSigninServiceProviderAsync(),
    watchSeviceProvidersSagaAsync(),
    watchAdminsSagaAsync(),
    watchSendInvitationSagaAsync(),
    watchSpouseSagaAsync(),
    watchUserProfessionalSagaAsync(),
    watchServiceProviderProfileSagaAsync(),
    watchRequestDocumentsSagaAsync(),
    watchLogsListSagaAsync(),
    watchSubUsersSagaAsync(),
    watchProfessionalPropertiesSagaAsync(),
    watchTryLeadPageSagaAsync(),
    watchLennarSubscriptionSagaAsync(),
    watchOtherProvidersSagaAsync(),
    watchRiskManagementSagaAsync(),
    watchLennarSignUpSagaAsync(),
    watchCountyRecordersSagaAsync(),
    watchExportCSVDetailsAsync(),
    watchUserDetailsFormSagaAsync(),
    watchAdminDashboardSagaAsync(),
  ]);
}
