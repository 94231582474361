import { call, put, takeEvery } from "redux-saga/effects";

import { getAPIPayload } from "../../../../apis/getApi";
import { postAPIPayload } from "../../../../apis/postApi";
import { deleteAPIPayload } from "../../../../apis/deleteApi";
import { propertyUsersAction } from "../../../slices/admin/propertyUsers/propertyUsersSlice";

export function* fetchPropertyUsersDataSaga({ payload }) {
  console.log("In sagas -->", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("Add leads  response => ", response);
  yield put(propertyUsersAction.fetchPropertyUsersList(response));
}

export function* fetchUserPropertiesDataSaga({ payload }) {
  console.log("In sagas -->", payload);
  const response = yield call(getAPIPayload, payload);
  console.log("Add leads  response => ", response);
  yield put(propertyUsersAction.fetchUserPropertiesData(response));
}

export function* fetchSecurityPINDataSaga({ payload }) {
  console.log("In sagas -->", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("Add leads  response => ", response);
  yield put(propertyUsersAction.fetchSecurityPINData(response));
}

export function* createUserSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);

  yield put(propertyUsersAction.setCreatedUser(response));
}

export function* fetchEditEmailDataSaga({ payload }) {
  console.log("In sagas -->", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("Add leads  response => ", response);
  yield put(propertyUsersAction.fetchUpdatedEmailData(response));
}

export function* fetchEditPhoneDataSaga({ payload }) {
  console.log("In sagas -->", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("Add leads  response => ", response);
  yield put(propertyUsersAction.fetchUpdatedPhoneData(response));
}

export function* getUserHistorySaga({ payload }) {
  const response = yield call(getAPIPayload, payload);
  yield put(propertyUsersAction.setUserHistory(response));
}

export function* addNoteSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  yield put(propertyUsersAction.setAddNote(response));
}

export function* deletePropertyUsersDetailsSaga({ payload }) {
  // return console.log("  ", payload);
  const response = yield call(deleteAPIPayload, payload);

  // set data in redux store
  // yield put(promocodeAction.setPromocodeDetails(response));
  yield put(propertyUsersAction.fetchDeletePropertyUsers(response));
}

export function* fetchPropertyUsersSaga({ payload }) {
  // return console.log("  ", payload);
  const response = yield call(getAPIPayload, payload);

  // set data in redux store
  yield put(propertyUsersAction.fetchDeletePropertyUsers(response));
}

export function* deletePropertyUserSaga({ payload }) {
  const response = yield call(deleteAPIPayload, payload);
  // set data in redux store
  yield put(propertyUsersAction.setDeletePropertyUserDetails(response));
}

export function* fetchDeletePropertyDataSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  yield put(propertyUsersAction.fetchDeletePropertyData(response));
}

export function* fetchEditedUserDataSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  yield put(propertyUsersAction.fetchEditedUserData(response));
}

export function* fetchCardListDataSaga({ payload }) {
  const response = yield call(getAPIPayload, payload);
  yield put(propertyUsersAction.fetchCardListData(response));
}

export function* fetchDeleteCardDataSaga({ payload }) {
  const response = yield call(deleteAPIPayload, payload);
  yield put(propertyUsersAction.fetchDeleteCardData(response));
}

export function* fetchSetDefaultCardDataSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  yield put(propertyUsersAction.fetchSetDefaultCardData(response));
}

export function* deleteNoteSaga({ payload }) {
  const response = yield call(deleteAPIPayload, payload);
  yield put(propertyUsersAction.setDeleteNoteDetails(response));
}

export function* activateInactivateCustomerSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  yield put(propertyUsersAction.setActivateInactivateDetails(response));
}

export function* resendWelcomeEmailSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  yield put(propertyUsersAction.fetchResendWelcomeEmailData(response));
}

export function* resetPasswordSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  yield put(propertyUsersAction.fetchResetPasswordData(response));
}

export function* internalAdminListSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  yield put(propertyUsersAction.fetchInternalAdminListData(response));
}

export function* updateReferralCodeSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  yield put(propertyUsersAction.fetchUpdateReferralCodeData(response));
}

export function* changeUserEmailsToSupportSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  yield put(propertyUsersAction.fetchUserEmailsToSupportData(response));
}

export function* watchPropertyUsersSagaAsync() {
  yield takeEvery(
    propertyUsersAction.getPropertyUsersData,
    fetchPropertyUsersDataSaga
  );
  yield takeEvery(
    propertyUsersAction.userPropertiesData,
    fetchUserPropertiesDataSaga
  );
  yield takeEvery(propertyUsersAction.getSecurityPIN, fetchSecurityPINDataSaga);
  yield takeEvery(propertyUsersAction.createUser, createUserSaga);
  yield takeEvery(propertyUsersAction.getUpdatedEmail, fetchEditEmailDataSaga);
  yield takeEvery(propertyUsersAction.getUpdatedPhone, fetchEditPhoneDataSaga);
  yield takeEvery(propertyUsersAction.getUserHistory, getUserHistorySaga);
  yield takeEvery(propertyUsersAction.addNote, addNoteSaga);
  yield takeEvery(
    propertyUsersAction.deletePropertyUsersDetails,
    deletePropertyUsersDetailsSaga
  );
  yield takeEvery(
    propertyUsersAction.fetchPropertyUsersData,
    fetchPropertyUsersSaga
  );
  yield takeEvery(
    propertyUsersAction.deletePropertyUser,
    deletePropertyUserSaga
  );
  yield takeEvery(
    propertyUsersAction.getDeletePropertyData,
    fetchDeletePropertyDataSaga
  );
  yield takeEvery(
    propertyUsersAction.getEditedUserData,
    fetchEditedUserDataSaga
  );
  yield takeEvery(propertyUsersAction.getCardListData, fetchCardListDataSaga);
  yield takeEvery(
    propertyUsersAction.getSetDefaultCardData,
    fetchSetDefaultCardDataSaga
  );
  yield takeEvery(
    propertyUsersAction.getDeleteCardData,
    fetchDeleteCardDataSaga
  );
  yield takeEvery(propertyUsersAction.deleteNote, deleteNoteSaga);
  yield takeEvery(
    propertyUsersAction.activateInactivateCustomer,
    activateInactivateCustomerSaga
  );
  yield takeEvery(
    propertyUsersAction.resendWelcomeEmailData,
    resendWelcomeEmailSaga
  );
  yield takeEvery(propertyUsersAction.resetPasswordData, resetPasswordSaga);
  yield takeEvery(propertyUsersAction.internalAdminList, internalAdminListSaga);
  yield takeEvery(
    propertyUsersAction.updateReferralCode,
    updateReferralCodeSaga
  );
  yield takeEvery(
    propertyUsersAction.changeUserEmailsToSupport,
    changeUserEmailsToSupportSaga
  );
}
