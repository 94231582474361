import React, { useEffect, useState } from "react";

import OtpInput from "react-otp-input";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";

import useTimer from "../hooks/useTimer";
import { Loader } from "../common/loader";
import { resendOTPSeconds, ROLES } from "../helpers/constants";
import AlertDialog from "../common/AlertDialog";
import ContcatAdminSupport from "../common/ContcatAdminSupport";
import { userSigupAction } from "../../redux/slices/user/userSignUpSlice";
import {
  autoFocusOnField,
  censorEmail,
  decryptPayload,
  getObjLength,
} from "../helpers/utils";

export const OTPFieldStyle = {
  background: "#F6F9FC",
  border: "1px solid rgba(52, 75, 91, 0.1)",
  borderRadius: "3px",
  width: "40px",
  height: "55px",
  fontWeight: "600",
  fontSize: "20px",
  lineHeight: "55px",
  textAlign: "center",
  color: "#0D120B",
};

const PersonalTokenScreen = (props) => {
  const navigate = useNavigate();
  const [otp, SetOTP] = useState("");
  const [otpErr, SetOTPErr] = useState(undefined);
  const [otpSucess, SetOTPSucess] = useState("");
  const [emailScreen, SetEmailScreen] = useState(false);
  const [open, setOpen] = useState(false);
  const [diableMobileBtn, setdiableMobileBtn] = useState(false);
  const [diableEmailBtn, setdiableEmailBtn] = useState(false);
  const [openEmail, setOpenEmail] = useState(false);
  const [emailOTP, setEmailOTP] = useState("");
  const [emailOTPErr, setEmailOTPErr] = useState(undefined);
  const [emailOTPSucess, setEmailOTPSucess] = useState("");
  const [emailSpamMsg, setEmailSpamMsg] = useState(
    " If you haven't received the email in your inbox, please check your email spam folder."
  );
  const [validate, setValidate] = useState(false);

  //initializes the timer states for hook
  const {
    timer,
    isTimerActive,
    startTimer,
    stopTimer,
    formatTimeForResendOTP,
  } = useTimer(120);

  const dispatch = useDispatch();
  const accCreated = useSelector((state) => state.signUpUser.createAccResponse);
  const otpResponse = useSelector(
    (state) => state.signUpUser.otpVerificationResponse
  );
  const resendOtpResponse = useSelector((state) => state.signUpUser.newOTP);
  const mobileVerified1 = useSelector((state) => state.signUpUser?.step1Mobile);
  const [mobileVerified, setMobileVerified] = useState(mobileVerified1);
  const emailOTPResponse = useSelector(
    (state) => state.signUpUser.emailOTPVerificationResponse
  );
  const resendEmailOtpResponse = useSelector(
    (state) => state.signUpUser.newEmailOTP
  );
  const emailVerified = useSelector((state) => state.signUpUser.step1Email);
  const resendPhone_OTP = useSelector(
    (state) => state.signUpUser.resendPhone_OTP
  );
  const resendEmail_OTP = useSelector(
    (state) => state.signUpUser.resendEmail_OTP
  );
  const signupDetails = useSelector(
    (state) => state.signUpUser.userSignupDetails
  );
  const { isBypassOptionAvailable } = useSelector((state) => state?.properties);

  const userDataFromSession = localStorage.getItem("userSignupDetails")
    ? JSON.parse(localStorage.getItem("userSignupDetails"))
    : "";

  const role = decryptPayload(localStorage.getItem("roles"));

  const isMobileVerified = JSON.parse(localStorage.getItem("emailScreen"));
  const userIdFromSession = JSON.parse(localStorage.getItem("user_Id"));
  console.log("signup details==m>>", signupDetails);
  console.log(
    userIdFromSession,
    "<<=== session values userSignupDetails==>>",
    userDataFromSession
  );
  useEffect(() => {
    if (isMobileVerified === 1) {
      SetEmailScreen(true);
      setMobileVerified(true);
      stopTimer();
    }
    localStorage.removeItem("referral_code");
    localStorage.removeItem("valueOf");
  }, []);

  useEffect(() => {
    console.log(props?.missingEmail, "props==>", props?.missingPhone);

    if (props?.missingPhone) {
      SetEmailScreen(true);
      setMobileVerified(true);
    }

    if (props?.missingEmail) {
      SetEmailScreen(false);
      setMobileVerified(true);
    }
  }, [props?.missingPhone, props?.missingEmail]);

  useEffect(() => {
    if (mobileVerified1) {
      // props.onBtnClick();
      handleClose();
    }

    if (Object.keys(resendOtpResponse).length > 0) {
      if (resendOtpResponse?.success) {
        handleClose();
      } else {
        validated();
      }
    }
  }, [mobileVerified1, resendOtpResponse]);

  useEffect(() => {
    if (emailVerified) {
      // props.onBtnClick();
      handleCloseEmailLoader();
    }

    if (Object.keys(resendEmailOtpResponse).length > 0) {
      if (resendEmailOtpResponse?.success) {
        handleCloseEmailLoader();
      } else {
        validatedEmail();
      }
    }
  }, [emailVerified, resendEmailOtpResponse]);

  useEffect(() => {
    if (Object.keys(otpResponse).length > 0 && !otpResponse.success) {
      validated();
    }
  }, [otpResponse]);

  useEffect(() => {
    if (Object.keys(emailOTPResponse).length > 0 && !emailOTPResponse.success) {
      validatedEmail();
    }
  }, [emailOTPResponse]);

  const validated = () => {
    if (!otpResponse.success || !resendOtpResponse?.success) {
      SetOTPErr(otpResponse?.data?.message || resendOtpResponse?.data?.message);
      SetOTPSucess("");
      handleClose();
      SetOTP("");
    } else {
      SetOTPErr("");
      handleClose();
    }
    setOpen(false);
  };
  const validatedEmail = () => {
    if (!emailOTPResponse.success || !resendEmailOtpResponse?.success) {
      setEmailOTPErr(
        emailOTPResponse?.data?.message || resendEmailOtpResponse?.data?.message
      );
      setEmailOTPSucess("");
      handleCloseEmailLoader();
      setEmailOTP("");
    } else {
      setEmailOTPErr("");
      handleCloseEmailLoader();
    }
    setOpenEmail(false);
  };
  const handleChange = (otp) => {
    SetOTPSucess("");
    if (otp.length === 6) {
      SetOTPErr("");
    }
    console.log("otp handled==>>", otp);
    SetOTP(otp);
    setValidate(false);
  };

  const handleChangeEmailOTP = (otp) => {
    setEmailOTPSucess("");
    if (otp.length === 6) {
      setEmailOTPErr("");
      setEmailSpamMsg("");
    }
    console.log("otp email handled==>>", otp);
    setEmailOTP(otp);
    setValidate(false);
  };

  const handleClose = () => {
    console.log("otp number==>", resendOtpResponse);

    if (otpResponse?.success) {
      SetOTPSucess(otpResponse?.message);
      SetEmailScreen(true);
      stopTimer();
      setMobileVerified(true);
      setdiableMobileBtn(true);
      if (props?.missingEmail) {
        dispatch(userSigupAction.skipEmailOTP(true));
      }
    }

    if (resendOtpResponse?.success) {
      SetOTPSucess(resendOtpResponse?.message);
      setdiableMobileBtn(false);
      SetOTPErr("");
    }

    setOpen(false);
  };
  const handleCloseEmailLoader = () => {
    console.log("otp email==>", resendEmailOtpResponse);
    if (emailOTPResponse?.success) {
      setEmailOTPSucess(emailOTPResponse?.message);
      setdiableEmailBtn(true);
    }
    if (resendEmailOtpResponse?.success) {
      setEmailOTPSucess(
        resendEmailOtpResponse?.message +
          " If you haven't received the email in your inbox, please check your email spam folder."
      );
      setEmailOTPErr("");
      setdiableEmailBtn(false);
    }
    setOpenEmail(false);
  };
  const handleToggle = () => {
    setOpen(true);
  };

  const otpValidate = (event) => {
    event.preventDefault();
    let payload = {};
    SetOTPSucess("");
    // SetEmailScreen(true);
    // props.onBtnClick();
    if (otp.length === 6) {
      let inputParam = {
        user_id: getObjLength(accCreated)
          ? accCreated.data?.user_id
          : userIdFromSession,
        verification_code: otp,
        step: 0,
        step_key: "VERIFY_PHONE_OTP",
      };
      autoFocusOnField();
      dispatch(userSigupAction.clearResedOTP());
      if (isBypassOptionAvailable) {
        payload = {
          url: "admin/verify-otp/phone",
          data: inputParam,
          token: true,
        };
      } else {
        payload = { url: "verify-otp/phone", data: inputParam };
      }
      dispatch(userSigupAction.userVerifyOTP(payload));
      handleToggle();
    } else {
      SetOTPErr("Please Enter Valid Token");
    }
  };

  const emailOTPValidate = (event) => {
    event.preventDefault();
    let payload = {};
    setEmailOTPSucess("");
    // props.onBtnClick();
    if (emailOTP.length === 6) {
      setEmailSpamMsg("");
      let inputParam = {
        user_id: getObjLength(accCreated)
          ? accCreated.data?.user_id
          : userIdFromSession,
        verification_code: emailOTP,
        step: 1,
        step_key: "VERIFY_EMAIL_OTP",
      };
      autoFocusOnField();
      dispatch(userSigupAction.clearResedOTP());
      if (isBypassOptionAvailable) {
        payload = {
          url: "admin/verify-otp/email",
          data: inputParam,
          token: true,
        };
      } else {
        payload = { url: "verify-otp/email", data: inputParam };
      }
      dispatch(userSigupAction.userVerifyEmailOTP(payload));
      setOpenEmail(!openEmail);
      // handleToggle();
    } else {
      setEmailOTPErr("Please Enter Valid Token");
    }
  };

  const resendOTP = () => {
    let payload = {};
    let inputParam = {
      user_id: getObjLength(accCreated)
        ? accCreated.data?.user_id
        : userIdFromSession,
    };
    if (isBypassOptionAvailable) {
      payload = {
        url: "admin/resend-otp/phone",
        data: inputParam,
        token: true,
      };
    } else {
      payload = { url: "resend-otp/phone", data: inputParam };
    }
    dispatch(userSigupAction.resedOTP(payload));
    handleToggle();
    SetOTP("");
    autoFocusOnField();
  };

  const resendEmailOTP = () => {
    let payload = {};
    setEmailSpamMsg("");
    let inputParam = {
      user_id: getObjLength(accCreated)
        ? accCreated.data?.user_id
        : userIdFromSession,
    };
    if (isBypassOptionAvailable) {
      payload = {
        url: "admin/resend-otp/email",
        data: inputParam,
        token: true,
      };
    } else {
      payload = { url: "resend-otp/email", data: inputParam };
    }
    dispatch(userSigupAction.resedEmailOTP(payload));
    setOpenEmail(!openEmail);
    setEmailOTP("");
    autoFocusOnField();
  };

  const resendOTPButtonClick = (type) => {
    if (type === "email") {
      resendEmailOTP();
    } else {
      resendOTP();
    }
    startTimer(resendOTPSeconds);
  };

  const goNext = () => {
    console.log("go next step==>");
    props.onBtnClick();
    stopTimer();
  };

  useEffect(() => {
    if (resendPhone_OTP) {
      resendOTP();
      resendEmailOTP();
    }
  }, [resendPhone_OTP]);
  useEffect(() => {
    if (resendEmail_OTP) {
      resendEmailOTP();
    }
  }, [resendEmail_OTP]);

  console.log(open, "<== loader false==>", openEmail);
  console.log(resendOtpResponse, "opt err==", otpResponse);
  let phn_num = userDataFromSession?.phone
    ? userDataFromSession?.phone
    : signupDetails?.data?.phone;
  let emailAdd = userDataFromSession?.phone
    ? userDataFromSession?.email
    : signupDetails?.data?.email;

  console.log(phn_num, "email substring==>", censorEmail(emailAdd));
  const phn_num1 = phn_num?.substring(phn_num?.length - 4);
  console.log("isMobileVerified==>", isMobileVerified);
  return (
    <>
      <Box
        className={`accountBoxWrapper ${
          isBypassOptionAvailable ? "bg-white" : ""
        }`}
      >
        {!emailScreen ? (
          <form onSubmit={otpValidate}>
            <Typography align="center" variant="h3">
              Enter{" "}
              {role?.includes(ROLES.admin) || role?.includes(ROLES.subAdmin)
                ? "User's"
                : "your"}{" "}
              personal token from Mobile number
            </Typography>
            <Typography align="center" variant="body1">
              Please enter the 6 digit security code we have sent to{" "}
              {role?.includes(ROLES.admin) || role?.includes(ROLES.subAdmin)
                ? "User's"
                : "your"}{" "}
              mobile phone * *** *** {phn_num1 ? phn_num1 : "4321"}.
            </Typography>
            <Box className="text-center otpBoxWrapper">
              <OtpInput
                className="otp mx-1"
                value={otp}
                isInputNum
                onChange={handleChange}
                numInputs={6}
                shouldAutoFocus
                disabled={diableMobileBtn}
                inputStyle={OTPFieldStyle}
              />
              {otpErr?.length > 0 && otpSucess?.length === 0 ? (
                <Typography variant="caption" className="text-danger 1" p={2}>
                  {otpErr}
                </Typography>
              ) : (
                <Typography variant="caption" className="text-success 3" p={2}>
                  {otpSucess}
                </Typography>
              )}
            </Box>
            <div className="text-center stepBtn mt-2">
              <Button
                type="submit"
                onClick={otpValidate}
                className="next_button mb-3"
                variant="contained"
                disabled={diableMobileBtn}
              >
                Verify mobile number
              </Button>
            </div>
            <div className="text-center tokenCodeText">
              {isTimerActive ? (
                <b>Resend OTP in {formatTimeForResendOTP(timer)} seconds</b>
              ) : (
                <>
                  Did not receive the code?
                  <Button
                    sx={{ textTransform: "none" }}
                    onClick={() => resendOTPButtonClick("phone")}
                  >
                    <Link className="text-blue text-decoration-none" to="">
                      Get a new one
                    </Link>
                  </Button>
                </>
              )}
            </div>
          </form>
        ) : (
          <form onSubmit={emailOTPValidate}>
            <Typography align="center" variant="h3">
              Enter{" "}
              {role?.includes(ROLES.admin) || role?.includes(ROLES.subAdmin)
                ? "User's"
                : "your"}{" "}
              personal token from Email ID
            </Typography>
            <Typography align="center" variant="body1">
              Please enter the 6 digit security code we have sent to{" "}
              {role?.includes(ROLES.admin) || role?.includes(ROLES.subAdmin)
                ? "User's"
                : "your"}{" "}
              Email ID &nbsp; {censorEmail(emailAdd)}.
            </Typography>
            <Box className="text-center otpBoxWrapper">
              <OtpInput
                className="otp mx-1"
                value={emailOTP}
                isInputNum
                onChange={handleChangeEmailOTP}
                numInputs={6}
                // shouldAutoFocus
                inputStyle={OTPFieldStyle}
                disabled={diableEmailBtn}
              />
              {emailOTPErr?.length > 0 && emailOTPSucess?.length === 0 ? (
                <Typography variant="caption" className="text-danger 1" p={2}>
                  {emailOTPErr}
                </Typography>
              ) : (
                <Typography variant="caption" className="text-success 3" p={2}>
                  {emailOTPSucess}
                </Typography>
              )}
              {emailSpamMsg?.length > 0 && (
                <Typography
                  variant="caption"
                  className="text-success 3 fw-500"
                  p={2}
                >
                  <b>{emailSpamMsg?.length > 0 && emailSpamMsg}</b>
                </Typography>
              )}
            </Box>
            <div className="text-center stepBtn mt-2">
              <Button
                type="submit"
                onClick={emailOTPValidate}
                className="next_button mb-3"
                variant="contained"
                disabled={diableEmailBtn}
              >
                Verify Email ID
              </Button>
            </div>
            <div className="text-center tokenCodeText">
              {isTimerActive ? (
                <b>Resend OTP in {formatTimeForResendOTP(timer)} seconds</b>
              ) : (
                <>
                  Did not receive the code?
                  <Button
                    sx={{ textTransform: "none" }}
                    onClick={() => resendOTPButtonClick("email")}
                  >
                    <Link className="text-blue text-decoration-none" to="">
                      Get a new one
                    </Link>
                  </Button>
                </>
              )}
            </div>
          </form>
        )}
      </Box>
      {/* Mobile Loader */}
      <Loader open={open} />
      {/*Email Loader */}
      <Loader open={openEmail} />
      {mobileVerified && emailVerified && (
        <AlertDialog
          type="Success"
          openPopup={mobileVerified && emailVerified}
          onClose={false}
          mesage={`${
            role?.includes(ROLES.admin) || role?.includes(ROLES.subAdmin)
              ? "The user's"
              : "Your"
          } ${
            props?.missingPhone || props?.missingEmail
              ? "contact details"
              : "mobile phone number and email"
          } were successfully verified.`}
          buttons={
            <div className="text-center stepBtn mt-4">
              <Button
                onClick={goNext}
                className="next_button "
                variant="contained"
              >
                Next Step
              </Button>
            </div>
          }
        />
      )}

      {props.errorPopup && (
        <ContcatAdminSupport
          message={otpErr || emailOTPErr}
          errorPopup={props.errorPopup}
          reduceErrorCount={props.reduceErrorCount}
        />
      )}
    </>
  );
};

export default PersonalTokenScreen;
