import React, { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { Search } from "@mui/icons-material";
import EmailIcon from "@mui/icons-material/Email";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  OutlinedInput,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";

import { Loader } from "../../common/loader";
import DialogBox from "../../common/DialogBox";
import AlertDialog from "../../common/AlertDialog";
import ResendToEmailForm from "./ResendToEmailForm";
import { PER_PAGE, ROLES } from "../../helpers/constants";
import SettingsIconSVG from "../../common/customSVGs/SettingsIconSVG";
import CustomDateRangePicker from "../../common/CustomDateRangePicker";
import TableHeaderForSorting from "../../common/TableHeaderForSorting";
import {
  mobileFilterResetBtnStyle,
  mobileFilterResultStyle,
} from "../AddLeads";
import CustomPagination from "../../common/customDesignedComponents/CustomPagination";
import { adminDashboardAction } from "../../../redux/slices/admin/admin-dashboard/adminDashboardSlice";
import { countyRecordersAction } from "../../../redux/slices/admin/county-recorder/countyRecorderSlice";
import { propertyRiskManagementAction } from "../../../redux/slices/admin/property-risk-management/propertyRiskManagementSlice";
import {
  decryptPayload,
  encryptPayload,
  getDateInYYYYMMDDFormat,
  getFormatedPhoneNumber,
  getLocalFormatedDate,
  getObjLength,
} from "../../helpers/utils";
const columns = [
  {
    key: 1,
    id: "no.",
    disablePadding: true,
    label: "No.",
    minWidth: 100,
    isSelecetAll: true,
  },
  {
    key: 2,
    id: "name",
    disablePadding: true,
    label: "User Name",
    minWidth: 120,
  },
  { key: 3, id: "email", disablePadding: true, label: "Email", minWidth: 110 },
  {
    key: 4,
    id: "address",
    disablePadding: true,
    label: "Address",
    minWidth: 197,
  },
  // {
  //   key: 5,
  //   id: "phone",
  //   disablePadding: true,
  //   label: "Phone Number",
  //   minWidth: 150,
  // },
  {
    key: 5,
    id: "free_subscription",
    disablePadding: true,
    label: "Free Monitoring",
    minWidth: 133,
  },
  {
    key: 6,
    id: "created_at",
    disablePadding: true,
    label: "Created",
    minWidth: 120,
    sorting: true,
    defaultValue: "desc",
  },
  {
    key: 7, // set this key as default for sorting
    id: "updated_at",
    disablePadding: true,
    label: "Modified",
    minWidth: 120,
    sorting: true,
    defaultValue: "desc",
  },
  {
    key: 8,
    id: "actions",
    disablePadding: true,
    label: "Actions",
    minWidth: 20,
  },
];

const filterRiskStatus = [
  {
    label: "Low",
    value: "low",
  },
  {
    label: "Medium",
    value: "medium",
  },
  {
    label: "Medium-High",
    value: "medium-high",
  },
  {
    label: "High",
    value: "high",
  },
];

const additionalCounties = [
  { county_name: "ep", county_domain_name: "Equity Protect" },
  { county_name: "Admin", county_domain_name: "Admin" },
  { county_name: "Generic Host", county_domain_name: "Generic Host" },
  { county_name: "lead", county_domain_name: "Lead-Generation" },
  { county_name: "Mike Gallagher", county_domain_name: "Mike Gallagher" },
  {
    county_name: "Dr. Sebastian Gorka",
    county_domain_name: "Dr. Sebastian Gorka",
  },
  { county_name: "Dennis Prager", county_domain_name: "Dennis Prager" },
];

function Row({
  row,
  open,
  handleMenuClick,
  page,
  index,
  handleViewEditUser,
  handleCollapsibleRow,
  isCollapsible = false,
  selectProperties,
  isItemSelected,
  labelId,
  county_admin = false,
}) {
  return (
    <React.Fragment>
      <TableRow
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        selected={isItemSelected}
        key={(page - 1) * 10 + index + 1}
        onClick={() => handleCollapsibleRow(row)}
        className="cursor-pointer"
        sx={{ backgroundColor: isCollapsible ? "rgba(3, 149, 255, 0.07)" : "" }}
      >
        <TableCell align="left">
          {!county_admin && (
            <Tooltip
              title="Select Property to delete"
              TransitionComponent={Zoom}
              placement="top"
              arrow
            >
              <Checkbox
                color="primary"
                checked={isItemSelected}
                inputProps={{
                  "aria-labelledby": labelId,
                }}
                onChange={(e) => {
                  selectProperties(e, row);
                }}
                onClick={(e) => e.stopPropagation()}
              />
            </Tooltip>
          )}
          {(page - 1) * 10 + index + 1}
        </TableCell>
        <TableCell align="left">{row?.name || "-"}</TableCell>
        <TableCell align="left">{row?.email || "-"}</TableCell>
        <TableCell align="left">
          {row?.address || "-"}
          {row?.unit_no ? " #" + row?.unit_no : null}
        </TableCell>
        {/* <TableCell align="left">
          {getFormatedPhoneNumber(row?.phone) || "-"}
        </TableCell> */}
        <TableCell align="left">
          {row?.free_subscription ? "Enable" : "Disable" || "-"}
        </TableCell>
        <TableCell align="left">
          {row?.created_at ? getLocalFormatedDate(row?.created_at) : "-"}
        </TableCell>
        <TableCell align="left">
          {row?.updated_at ? getLocalFormatedDate(row?.updated_at) : "-"}
        </TableCell>
        <TableCell sx={{ width: 180 }} align="left">
          <Stack direction="row" spacing={1}>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleMenuClick(e, row);
              }}
              size="small"
              className="settings-button"
              aria-controls={open ? "settings-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <SettingsIconSVG />
            </IconButton>

            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => handleCollapsibleRow(row)}
            >
              {isCollapsible ? (
                <KeyboardArrowUpIcon style={{ color: "#15BE53" }} />
              ) : (
                <KeyboardArrowDownIcon style={{ color: "#15BE53" }} />
              )}
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>
      <TableRow
        className="collapsible-row"
        sx={{ backgroundColor: "rgba(3, 149, 255, 0.07)" }}
      >
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0 }}
          colSpan={1}
        ></TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={1}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "100px",
                maxHeight: "200px",
                overflow: "hidden",
                overflowY: "auto",
              }}
            >
              <Stack direction="column">
                <Typography
                  variant="h6"
                  className="content-heading"
                  component="div"
                >
                  Risk Level
                </Typography>
                <Typography className="content-text m-0 fw-600" component="div">
                  {row?.status?.includes("Low") ? (
                    <span className="text-success text-capitalize f">
                      {row?.status}
                    </span>
                  ) : row?.status?.includes("Medium") ? (
                    <span className="text-primary text-capitalize">
                      {row?.status}
                    </span>
                  ) : (
                    <span className="text-danger text-capitalize">
                      {row?.status}
                    </span>
                  )}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={1}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "100px",
                maxHeight: "200px",
                overflow: "hidden",
                overflowY: "auto",
              }}
            >
              <Stack direction="column">
                <Typography
                  variant="h6"
                  className="content-heading"
                  component="div"
                >
                  Phone Number
                </Typography>
                <Typography className="content-text m-0 fw-600" component="div">
                  {getFormatedPhoneNumber(row?.phone) || "-"}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={6}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "100px",
                maxHeight: "200px",
                overflow: "hidden",
                overflowY: "auto",
              }}
            >
              <Stack direction="column">
                <Typography
                  variant="h6"
                  className="content-heading"
                  component="div"
                >
                  Source
                </Typography>
                <Typography className="content-text m-0 fw-600" component="div">
                  {getObjLength(row?.county)
                    ? row?.county?.county_name
                    : row?.source}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const AdminFreeMonitoringListTable = ({
  setPropertiesIDList = () => {},
  propertiesIDList,
  setDeletePropertyModal = () => {},
  deletePropertyModal,
  handleDeleteProperty = () => {},
  setSelectAllFlag = () => {},
  selectAllFlag = false,
  setCSV_Payload = () => {},
}) => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // states
  const [page, setPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isCollapsibleRow, setIsCallapsibleRow] = useState(false);
  const [selectedCollapsibleRow, setSelectedCollapsibleRow] = useState(null);
  const [selectedKey, setSelectedKey] = useState("all");
  const [selectedTerms, setSelectedTerms] = useState("");
  const [selectedSource, setSelectedSource] = useState("");
  const [selectedRiskLevel, setSelectedRiskLevel] = useState("");
  const [toggleFilterOptions, setToggleFilterOptions] = useState(false);
  const [payload, setPayload] = useState({});
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [selectedDateType, setSelectedDateType] = useState("created_at");
  const [selectMonitoringStatus, setSelectMonitoringStatus] = useState(false);
  const [isFilterApiCalled, setIsFilterApiCalled] = useState(false);
  const [directionFlow, setDirectionFlow] = useState(true);
  const [selectedColumn, setSelectedColumn] = useState(7); // updated_at key for sorting
  const [selectedRow, setSelectedRow] = useState(null);
  const [subscribeModal, setSubscribeModal] = useState(false);
  const [subscribeAction, setSubscribeAction] = useState(1);
  const [hideSelectAllCheckbox, setHideSelectAllCheckbox] = useState(true);
  const [ResendMailModal, setResendMailModal] = useState(false);
  const [openResendMailModal, setOpenResendMailModal] = useState(false);

  // store data
  const {
    monitoredPropertyListLoader,
    riskManagementLoader,
    monitoredPropertyList,
    subscribeFreeMonitoring,
    deleteMonitoredPropertyData,
    resendReportMailData,
  } = useSelector((state) => state?.propertyRiskManagement);

  const { registeredCountyListData } = useSelector(
    (state) => state.countyRecorders
  );

  const { userProfileDetails } = useSelector((state) => state?.userProfile);

  const { monitoringPropertiesFilterData } = useSelector(
    (state) => state?.adminDashboard
  );

  let tempMonitoringFilterData =
    JSON.parse(
      decryptPayload(localStorage.getItem("tempMonitoringFilterData"))
    ) || null;

  let fromDashboard = window.location.search
    ?.split("?")[1]
    ?.replace("fromDashboard=", "");
  let tokenValue = fromDashboard?.split("&&");

  //when user opend the cards in new tab
  if (
    fromDashboard !== "free-monitoring" &&
    tokenValue?.[0] === "free-monitoring"
  ) {
    tempMonitoringFilterData = JSON.parse(
      decryptPayload(tokenValue?.[1]) || null
    );
  }

  const open = Boolean(anchorEl);

  const _DATA = monitoredPropertyList?.data ? monitoredPropertyList?.data : [];
  const count = Math.ceil(monitoredPropertyList?.count / PER_PAGE);

  const handleMenuClose = () => {
    setPropertiesIDList([]);
    setAnchorEl(null);
  };

  const handleMenuClick = (event, data) => {
    setPropertiesIDList([]);
    setSelectedRow(data);
    setAnchorEl(event.currentTarget);
  };

  // handle page change
  const handlePageChange = (e, p) => {
    setPropertiesIDList([]);
    setPage(p);
    setPayload((prevState) => ({ ...prevState, offset: (p - 1) * PER_PAGE }));
  };

  //collapsible row
  const handleCollapsibleRow = (row) => {
    if (selectedCollapsibleRow?.id === row?.id && isCollapsibleRow) {
      setIsCallapsibleRow(false);
      return;
    }
    setIsCallapsibleRow(true);
    setSelectedCollapsibleRow(row);
  };

  // for sorting
  const handleSorting = (column) => {
    setDirectionFlow(!directionFlow);
    console.log(column, "Sorting function called", payload);
    console.log("Sorting flow", directionFlow);
    setPayload((prevState) => ({
      ...prevState,
      order_by_column: column,
      sorting_order: !directionFlow ? "desc" : "asc",
    }));
  };

  const filterOptions = () => {
    if (
      selectedTerms.length > 0 ||
      selectedDateRange ||
      selectMonitoringStatus ||
      selectedRiskLevel ||
      selectedSource
    ) {
      handleURLParameters();
      console.log("selector==>", selectedDateRange);
      setPage(1);
      setPayload((prevState) => ({
        ...prevState,
        type: selectedKey || undefined,
        value:
          selectedTerms ||
          // selectMonitoringStatus ||
          // selectedRiskLevel ||
          undefined,
        source: selectedSource,
        monitoring_status: selectMonitoringStatus,
        risk_level: selectedRiskLevel,
        // from_date: selectedDateRange
        //   ? getDateInYYYYMMDDFormat(new Date(selectedDateRange?.startDate))
        //   : undefined,
        // to_date: selectedDateRangeindividual
        //   ? getDateInYYYYMMDDFormat(new Date(selectedDateRange?.endDate))
        //   : undefined,
        from_date:
          (selectedKey === "created_at" || selectedDateType === "created_at") &&
          selectedDateRange
            ? getDateInYYYYMMDDFormat(new Date(selectedDateRange?.startDate))
            : undefined,
        to_date:
          (selectedKey === "created_at" || selectedDateType === "created_at") &&
          selectedDateRange
            ? getDateInYYYYMMDDFormat(new Date(selectedDateRange?.endDate))
            : undefined,
        modified_from_date:
          (selectedKey === "updated_at" || selectedDateType === "updated_at") &&
          selectedDateRange
            ? getDateInYYYYMMDDFormat(new Date(selectedDateRange?.startDate))
            : undefined,
        modified_to_date:
          (selectedKey === "updated_at" || selectedDateType === "updated_at") &&
          selectedDateRange
            ? getDateInYYYYMMDDFormat(new Date(selectedDateRange?.endDate))
            : undefined,
        limit: PER_PAGE,
        offset: 0,
      }));
      setIsFilterApiCalled(true);
    }
  };

  const resetData = () => {
    handleURLParameters();
    if (isFilterApiCalled) {
      setPayload({ limit: PER_PAGE, offset: 0 });
      setPage(1);
      setIsFilterApiCalled(false);
    }
    setSelectedKey("all");
    setSelectedTerms("");
    setSelectedDateType("created_at");
    setSelectedDateRange(null);
    setSelectMonitoringStatus(undefined);
    setSelectedSource(undefined);
    setSelectedRiskLevel(undefined);
    fromDashboard = null;
  };

  console.log("selectedRow: ", payload);

  //   get logs details
  const getSubscribePropertiesList = useCallback(() => {
    localStorage.removeItem("tempMonitoringFilterData");
    let data = {
      type: payload?.type,
      value:
        payload?.type === "free_subscription"
          ? parseInt(payload?.value)
          : payload?.value,
      user_id: params?.user_id ? params?.user_id : undefined,
      from_date: payload?.from_date,
      to_date: payload?.to_date,
      modified_from_date: payload?.modified_from_date,
      modified_to_date: payload?.modified_to_date,
      source: payload?.source,
      monitoring_status: payload?.monitoring_status,
      risk_level: payload?.risk_level,
      limit: PER_PAGE,
      offset: payload?.offset || 0,
      order_by_column: payload?.order_by_column || "updated_at",
      sorting_order: payload?.sorting_order || "desc",
    };
    setCSV_Payload(data);
    dispatch(
      propertyRiskManagementAction?.freeMonitoringList({
        url: `user-risk-reports`,
        data,
        token: true,
      })
    );
  }, [
    dispatch,
    payload?.type,
    payload?.value,
    payload?.from_date,
    payload?.to_date,
    payload?.modified_from_date,
    payload?.modified_to_date,
    payload?.source,
    payload?.monitoring_status,
    payload?.risk_level,
    payload?.offset,
    payload?.order_by_column,
    payload?.sorting_order,
    params?.user_id,
  ]);

  // API call when Admin redirect from the New dashboard
  const getSubscribePropertiesListAPI = (filterData) => {
    let data = {
      limit: PER_PAGE,
      offset: payload?.offset || 0,
      order_by_column: payload?.order_by_column || "updated_at",
      sorting_order: payload?.sorting_order || "desc",
    };
    if (getObjLength(filterData)) {
      data = { ...data, ...filterData };
    }
    console.log("KK Get property list-->", data);
    if (getObjLength(filterData)) {
      localStorage.setItem(
        "tempMonitoringFilterData",
        encryptPayload(JSON.stringify(data))
      );
    }

    dispatch(
      propertyRiskManagementAction?.freeMonitoringList({
        url: `user-risk-reports`,
        data,
        token: true,
      })
    );
  };
  // To clear the URL prams and manage the API filters accordingly
  const handleURLParameters = () => {
    localStorage.removeItem("tempMonitoringFilterData");
    dispatch(adminDashboardAction.getMonitoringPropertiesFilterData({}));
    const currentUrl = window.location.href;
    // Check if the URL contains the query parameter 'abcd'
    if (currentUrl?.includes(fromDashboard)) {
      // Remove the query parameter
      const newUrl = currentUrl?.replace(`?fromDashboard=${fromDashboard}`, "");

      // Use react-router-dom to navigate to the new URL
      navigate(newUrl?.replace(window.location.origin, ""));
    }
  };

  // For manage API calls for menu actions componnets
  const handleMonitoringListAPICall = (action) => {
    if (
      (fromDashboard === "free-monitoring" ||
        tokenValue?.[0] === "free-monitoring") &&
      (getObjLength(monitoringPropertiesFilterData) ||
        getObjLength(tempMonitoringFilterData)) // to set API Call with the specific filter data
    ) {
      getSubscribePropertiesListAPI(
        monitoringPropertiesFilterData || tempMonitoringFilterData
      );
    } else {
      getSubscribePropertiesList(action); // default API call with exising filter data
    }
  };

  useEffect(() => {
    // when admin redirect from the admin dashboard's subscription section
    const filterData =
      (getObjLength(monitoringPropertiesFilterData) &&
        monitoringPropertiesFilterData) ||
      (getObjLength(tempMonitoringFilterData) && tempMonitoringFilterData);
    console.log("subscriptionModuleFilterData==>", filterData);

    if (
      (fromDashboard === "free-monitoring" ||
        tokenValue?.[0] === "free-monitoring") &&
      getObjLength(filterData)
    ) {
      setToggleFilterOptions(true);
      // setSelectedKey(filterData?.type);
      if (filterData?.monitoring_status) {
        setSelectMonitoringStatus(filterData?.monitoring_status?.toString());
      }
      getSubscribePropertiesListAPI(filterData);
    } else {
      getSubscribePropertiesList();
    }
  }, [dispatch, getSubscribePropertiesList, fromDashboard]);

  useEffect(() => {
    if (
      !decryptPayload(localStorage.getItem("roles"))?.includes(
        ROLES.countyRecorder
      )
    ) {
      dispatch(
        countyRecordersAction.registeredCountyList({
          url: `admin/counties`,
          data: {},
          token: true,
        })
      );
    }
  }, []);

  const subscribeFreeMonitor = () => {
    setSubscribeModal(false);
    dispatch(
      propertyRiskManagementAction.subscribeFreeMonitoring({
        url: `county-subscription/${selectedRow?.id}/${subscribeAction}`,
        data: {
          by_admin: true,
          user_id: userProfileDetails?.data?.user_id || 1,
        },
        token: true,
      })
    );
  };

  const deleteMonitoredProperty = () => {
    setDeletePropertyModal(false);
    dispatch(
      propertyRiskManagementAction.deleteMonitoredProperty({
        url: `delete-user-risk-reports`,
        data: { riskReport: propertiesIDList, device_type: "web" },
        token: true,
      })
    );
  };

  const handleFreeMonitoring = (action) => {
    setSubscribeModal(true);
    setSubscribeAction(action);
  };

  const closeSubscribeModal = () => {
    console.log("deleteMonitoredPropertyData==>", deleteMonitoredPropertyData);
    if (
      subscribeFreeMonitoring?.success ||
      deleteMonitoredPropertyData?.success
    ) {
      dispatch(
        propertyRiskManagementAction.clearPropertyRiskManagementData({})
      );
      if (monitoredPropertyList?.data?.county) {
        setPayload({ limit: PER_PAGE, offset: 0 });
      }
      handleMonitoringListAPICall();
    }
    // else {
    //   navigate("/property-risk-management");
    // }
    dispatch(propertyRiskManagementAction.fetchSubscribeFreeMonitoring({}));
    dispatch(
      propertyRiskManagementAction.fetchDeletedMonitoredPropertyData({})
    );
    setPropertiesIDList([]);
  };
  //for select proeprties
  const onSelectAllClick = (event) => {
    console.log("onSelectAllClick--<", event.target.checked);

    if (event.target.checked) {
      const newSelected = _DATA?.map((n) => n.id);
      if (newSelected?.length === 1) {
        setSelectedRow(_DATA?.[0]);
      }
      setPropertiesIDList(newSelected);
      return;
    }
    setPropertiesIDList([]);
  };

  const selectProperties = (event, row) => {
    if (event.target.checked) {
      setSelectedRow(row);
    }
    const selectedIndex = propertiesIDList.indexOf(row?.id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(propertiesIDList, row?.id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(propertiesIDList.slice(1));
    } else if (selectedIndex === propertiesIDList.length - 1) {
      newSelected = newSelected.concat(propertiesIDList.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        propertiesIDList.slice(0, selectedIndex),
        propertiesIDList.slice(selectedIndex + 1)
      );
    }
    setPropertiesIDList(newSelected);
  };
  const isSelected = (id) => propertiesIDList?.indexOf(id) !== -1;

  useEffect(() => {
    if (
      monitoredPropertyList?.data?.length === 0 &&
      monitoredPropertyList?.count > 10
    ) {
      console.log("go to previous page...");
      setPage(parseInt(page) - 1);
      setPayload((prevState) => ({
        ...prevState,
        limit: PER_PAGE,
        offset: parseInt(monitoredPropertyList?.count) - 10,
      }));
    }
    if (monitoredPropertyList?.data?.length > 0) {
      console.log("YY display");
      setHideSelectAllCheckbox(true);
    } else {
      console.log("YY hide");
      setHideSelectAllCheckbox(false);
    }
  }, [monitoredPropertyList, page]);
  let county_admin = decryptPayload(localStorage.getItem("roles"))?.includes(
    ROLES.countyRecorder
  );
  console.log("selectedSource-->", selectedSource);

  const combinedCountyList = [
    ...additionalCounties,
    ...(registeredCountyListData?.data || []),
  ];
  const handleCountyChange = (event, newValue) => {
    const selectedCounty = combinedCountyList?.find(
      (county) => county.county_domain_name === newValue
    );

    if (getObjLength(selectedCounty)) {
      setSelectedSource(selectedCounty.county_name);
    }
  };
  const closeDialogBox = () => {
    setResendMailModal(false);
  };
  const onSubmit = (values) => {
    console.log("values-->", values);
    dispatch(
      propertyRiskManagementAction.fetchResendReportMailData({
        url: `resend-risk-report`,
        data: { ...values, riskReportId: selectedRow?.id },
        token: true,
      })
    );
  };
  const closeResendReportModal = () => {
    if (getObjLength(resendReportMailData) && resendReportMailData?.success) {
      handleMonitoringListAPICall();
      setResendMailModal(false);
    } else {
      setResendMailModal(true);
    }
    setOpenResendMailModal(false);

    dispatch(propertyRiskManagementAction.getResendReportMailData({}));
  };

  useEffect(() => {
    if (getObjLength(resendReportMailData)) {
      setOpenResendMailModal(true);
      setResendMailModal(false);
    }
  }, [resendReportMailData]);

  return (
    <>
      {monitoredPropertyList?.success ? (
        <>
          {/* Filtering Component Section */}
          <Box
            id="filter"
            onClick={() => setToggleFilterOptions(!toggleFilterOptions)}
          >
            <h3 className="filter-text">Filter your results</h3>
            <ArrowDropDownIcon
              className="filter-arrow"
              sx={{ ms: 1, fontSize: "20px" }}
            />
          </Box>
          {/* Filtering Options */}
          <Box
            sx={{
              overflow: "hidden",
              overflowX: "auto",
              mb: 1.5,
            }}
          >
            {toggleFilterOptions && (
              <>
                {/* for mobile view */}
                <Box className="d-block d-none">
                  <Grid
                    container
                    className="bg-white d-flex justify-content-around align-items-center px-4"
                    spacing={2}
                    pb={2}
                    pt={2}
                  >
                    <Grid item xs={6} sm={6} md={4} lg={2}>
                      <Typography className="search-text">Search By</Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} lg={2}>
                      <FormControl
                        className="filter-select-one"
                        sx={{ width: "100%" }}
                      >
                        <TextField
                          id="outlined-select-currency"
                          select
                          label="All Types"
                          name="type"
                          onChange={(event) => {
                            setSelectedKey(event.target.value);
                            setSelectedTerms("");
                            setSelectedDateRange(null);
                            setSelectMonitoringStatus(null);
                            setSelectedRiskLevel(null);
                          }}
                          value={selectedKey}
                        >
                          <MenuItem value={"all"}>All</MenuItem>
                          {columns.map(
                            (cell) =>
                              cell.label !== "No." &&
                              cell.label !== "Actions" && (
                                <MenuItem key={cell.id} value={cell.id}>
                                  {cell.label}
                                </MenuItem>
                              )
                          )}
                          <MenuItem value={"status"}>Risk Level</MenuItem>
                          {!county_admin && (
                            <MenuItem value={"county"}>Source</MenuItem>
                          )}
                        </TextField>
                      </FormControl>
                    </Grid>
                    {selectedKey === "status" && (
                      <Grid item xs={12} sm={12} md={4} lg={2}>
                        <FormControl
                          className="filter-search"
                          sx={{ width: "100%" }}
                          variant="outlined"
                        >
                          <TextField
                            id="outlined-select-currency"
                            select
                            label="Risk Level"
                            name="type"
                            onChange={(event) => {
                              setSelectedRiskLevel(event.target.value);
                              setSelectedTerms("");
                              setSelectedDateRange(null);
                              setSelectMonitoringStatus(null);
                            }}
                            value={selectedRiskLevel}
                          >
                            {filterRiskStatus.map((cell) => (
                              <MenuItem key={cell.value} value={cell.value}>
                                {cell.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </FormControl>
                      </Grid>
                    )}

                    {selectedKey !== "created_at" &&
                      selectedKey !== "updated_at" &&
                      selectedKey !== "status" &&
                      selectedKey !== "county" &&
                      selectedKey !== "free_subscription" && (
                        <Grid item xs={12} sm={12} md={4} lg={2}>
                          <FormControl
                            className="filter-search"
                            sx={{ width: "100%" }}
                            variant="outlined"
                          >
                            <InputLabel>Enter search term</InputLabel>
                            <OutlinedInput
                              id="outlined-adornment-password"
                              endAdornment={
                                <InputAdornment position="end">
                                  <Search />
                                </InputAdornment>
                              }
                              label="Enter search term"
                              name="terms"
                              onChange={(event) => {
                                setSelectedTerms(event.target.value);
                                setSelectMonitoringStatus(null);
                                setSelectedRiskLevel(null);
                              }}
                              value={selectedTerms}
                            />
                          </FormControl>
                        </Grid>
                      )}

                    {selectedKey === "county" && (
                      <Grid item xs={12} sm={12} md={4} lg={2}>
                        <FormControl
                          className="filter-search"
                          sx={{ width: "100%" }}
                          variant="outlined"
                        >
                          <TextField
                            id="outlined-select-currency"
                            select
                            label="Source List"
                            // value={countyId}
                            // onChange={(e) => setCountyId(e.target.value.toString())}
                            value={selectedTerms}
                            onChange={(e) =>
                              setSelectedTerms(e.target.value.toString())
                            }
                          >
                            <MenuItem value={"ep"}>Equity Protect</MenuItem>
                            <MenuItem value={"Generic Host"}>
                              Generic Host
                            </MenuItem>
                            <MenuItem value={"lead"}>Lead-Generation</MenuItem>
                            {registeredCountyListData?.data?.map(
                              (county, key) => (
                                <MenuItem value={county?.county_name}>
                                  {county?.county_domain_name}
                                </MenuItem>
                              )
                            )}
                          </TextField>
                        </FormControl>
                      </Grid>
                    )}

                    {(selectedKey === "created_at" ||
                      selectedKey === "updated_at") && (
                      <Grid item xs={12} sm={12} md={2} lg={2}>
                        <FormControl className="filter-search w-100">
                          <CustomDateRangePicker
                            rangeValue={selectedDateRange}
                            onChange={(values) => {
                              setSelectedDateRange(values);
                            }}
                            onClear={() => {
                              setSelectedDateRange(null);
                            }}
                          />
                        </FormControl>
                      </Grid>
                    )}

                    {selectedKey !== "created_at" &&
                      selectedKey !== "updated_at" &&
                      selectedKey !== "status" &&
                      selectedKey === "free_subscription" && (
                        <Grid iitem xs={12} sm={12} md={4} lg={2}>
                          <FormControl
                            className="filter-search mt-3"
                            sx={{ width: "100%" }}
                          >
                            <TextField
                              id="outlined-select-currency"
                              select
                              label="Status"
                              defaultValue={selectMonitoringStatus}
                              onChange={(e) => {
                                setSelectMonitoringStatus(e.target.value);
                                setSelectedDateRange(null);
                                setSelectedRiskLevel(null);
                                fromDashboard = null;
                              }}
                            >
                              <MenuItem value={"1"}>Enable</MenuItem>
                              <MenuItem value={"0"}>Disable</MenuItem>
                            </TextField>
                          </FormControl>
                        </Grid>
                      )}

                    <Grid item xs={12} sm={12} md={2} lg={2}>
                      <FormControl className="w-100">
                        <Button
                          onClick={filterOptions}
                          sx={mobileFilterResultStyle}
                        >
                          Filter Results
                        </Button>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={2}
                      lg={2}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <Button
                        type="reset"
                        onClick={() => {
                          resetData();
                        }}
                        sx={mobileFilterResetBtnStyle}
                      >
                        Reset
                      </Button>
                    </Grid>
                  </Grid>
                </Box>

                {/* for desktop view */}
                <Card
                  // id="filter-options-card"
                  className="filter-options-carddiv"
                >
                  <Grid container className="filter-options-box">
                    <Grid item>
                      <Stack direction="row" spacing={2} alignItems="center">
                        <div className="search-text mx-2">Search By</div>
                        <FormControl className="filter-select-one">
                          <TextField
                            id="outlined-select-currency"
                            select
                            label="All Types"
                            name="type"
                            onChange={(event) => {
                              setSelectedKey(event.target.value);
                              setSelectedTerms("");
                              setSelectedDateRange(null);
                              setSelectMonitoringStatus(undefined);
                              setSelectedRiskLevel(undefined);
                              if (event.target.value === "all") {
                                setSelectedDateType("created_at");
                              } else {
                                setSelectedDateType("");
                              }
                            }}
                            value={selectedKey}
                          >
                            <MenuItem value={"all"}>All</MenuItem>
                            {columns.map(
                              (cell) =>
                                cell.label !== "No." &&
                                cell.label !== "Actions" && (
                                  <MenuItem key={cell.id} value={cell.id}>
                                    {cell.label}
                                  </MenuItem>
                                )
                            )}
                            <MenuItem value={"status"}>Risk Level</MenuItem>
                            {!county_admin && (
                              <MenuItem value={"county"}>Source</MenuItem>
                            )}
                          </TextField>
                        </FormControl>
                      </Stack>
                    </Grid>

                    {selectedKey !== "created_at" &&
                      selectedKey !== "updated_at" &&
                      selectedKey !== "status" &&
                      selectedKey !== "county" &&
                      selectedKey !== "free_subscription" && (
                        <Grid item>
                          <FormControl
                            className="filter-search"
                            variant="outlined"
                          >
                            <InputLabel>Enter search term</InputLabel>
                            <OutlinedInput
                              id="outlined-adornment-password"
                              endAdornment={
                                <InputAdornment position="end">
                                  <Search />
                                </InputAdornment>
                              }
                              label="Enter search term"
                              name="terms"
                              onChange={(event) => {
                                setSelectedTerms(event.target.value);
                                // setSelectMonitoringStatus(null);
                                // setSelectedRiskLevel(null);
                              }}
                              value={selectedTerms}
                            />
                          </FormControl>
                        </Grid>
                      )}

                    {(selectedKey === "all" || selectedKey === "county") && (
                      <Grid item>
                        <FormControl className="filter-select-one">
                          <Autocomplete
                            id="asynchronous-demo"
                            disableClearable
                            options={combinedCountyList?.map(
                              (option) => option?.county_domain_name
                            )}
                            onChange={handleCountyChange}
                            renderInput={(params) => (
                              <TextField {...params} label="Source" />
                            )}
                          />
                        </FormControl>
                      </Grid>
                    )}

                    {(selectedKey === "all" ||
                      selectedKey === "free_subscription") &&
                      selectedKey !== "status" &&
                      selectedKey !== "created_at" &&
                      selectedKey !== "updated_at" && (
                        <Grid item>
                          <FormControl className="filter-select-one">
                            <TextField
                              id="outlined-select-currency"
                              select
                              label="Free Monitoring"
                              defaultValue={selectMonitoringStatus}
                              onChange={(e) => {
                                setSelectMonitoringStatus(e.target.value);
                                if (selectedKey !== "all") {
                                  setSelectedSource(undefined);
                                  setSelectedDateRange(undefined);
                                  setSelectedRiskLevel(undefined);
                                  fromDashboard = null;
                                }
                              }}
                            >
                              <MenuItem value={"1"}>Enable</MenuItem>
                              <MenuItem value={"0"}>Disable</MenuItem>
                            </TextField>
                          </FormControl>
                        </Grid>
                      )}

                    {(selectedKey === "all" || selectedKey === "status") && (
                      <Grid item>
                        <FormControl className="filter-select-one">
                          <TextField
                            id="outlined-select-currency"
                            select
                            label="Risk Level"
                            name="type"
                            onChange={(event) => {
                              setSelectedRiskLevel(event.target.value);
                              if (selectedKey !== "all") {
                                setSelectedSource(undefined);
                                setSelectedTerms("");
                                setSelectedDateRange(undefined);
                                setSelectMonitoringStatus(undefined);
                              }
                            }}
                            value={selectedRiskLevel}
                          >
                            {filterRiskStatus.map((cell) => (
                              <MenuItem key={cell.value} value={cell.value}>
                                {cell.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </FormControl>
                      </Grid>
                    )}

                    {selectedKey === "all" && (
                      <Grid item>
                        <FormControl className="filter-select-one">
                          <TextField
                            id="outlined-select-currency"
                            select
                            label="Date type"
                            value={selectedDateType}
                            onChange={(e) => {
                              setSelectedDateType(e.target.value);
                              setSelectedDateRange(null);
                            }}
                          >
                            {[
                              { label: "Created At", value: "created_at" },
                              { label: "Updated At", value: "updated_at" },
                            ].map((data, key) => (
                              <MenuItem value={data.value}>
                                {data.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </FormControl>
                      </Grid>
                    )}

                    {(selectedKey === "all" ||
                      selectedKey === "created_at" ||
                      selectedKey === "updated_at") && (
                      <Grid item>
                        <FormControl className="filter-search">
                          <CustomDateRangePicker
                            // label={
                            //   selectedDateType === "created_at"
                            //     ? "Created At"
                            //       ? selectedDateType === "created_at"
                            //       : "Updated At"
                            //     : null
                            // }
                            rangeValue={selectedDateRange}
                            onChange={(values) => {
                              setSelectedDateRange(values);
                            }}
                            onClear={() => {
                              setSelectedDateRange(null);
                            }}
                          />
                        </FormControl>
                      </Grid>
                    )}
                    <Grid item>
                      <Stack direction="row" spacing={2} alignItems="center">
                        <FormControl>
                          <Button
                            onClick={filterOptions}
                            className="filter-button "
                          >
                            Filter Results
                          </Button>
                        </FormControl>
                        <Button
                          type="reset"
                          onClick={() => {
                            resetData();
                          }}
                          className="reset-filters"
                        >
                          Reset
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </Card>
              </>
            )}
          </Box>

          {/* Listing Table */}
          <Box sx={{ width: "100%" }}>
            <Paper sx={{ width: "100%", mb: 2 }}>
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  stickyHeader
                >
                  <TableHeaderForSorting
                    columns={columns}
                    handleSorting={handleSorting}
                    directionFlow={directionFlow}
                    setSelectedColumn={setSelectedColumn}
                    selectedColumn={selectedColumn}
                    isSelecetAll={true}
                    onSelectAllClick={onSelectAllClick}
                    numSelected={propertiesIDList?.length}
                    rowCount={_DATA?.length}
                    hideSelectAllCheckbox={
                      hideSelectAllCheckbox && !county_admin
                    }
                  />
                  <TableBody checkboxSelection>
                    {_DATA?.map((row, index) => {
                      const isItemSelected = isSelected(row?.id);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <Row
                          key={(page - 1) * 10 + index + 1}
                          row={row}
                          open={open}
                          handleMenuClick={handleMenuClick}
                          selectProperties={selectProperties}
                          propertiesIDList={propertiesIDList}
                          page={page}
                          index={index}
                          handleCollapsibleRow={handleCollapsibleRow}
                          isCollapsible={
                            selectedCollapsibleRow?.id === row?.id &&
                            isCollapsibleRow
                          }
                          labelId={labelId}
                          isItemSelected={isItemSelected}
                          county_admin={county_admin}
                        />
                      );
                    })}
                  </TableBody>
                </Table>
                {!monitoredPropertyList?.data?.length > 0 &&
                  !monitoredPropertyListLoader && (
                    <Typography className="p-5 text-center" variant="h6">
                      No Data Found.
                    </Typography>
                  )}
              </TableContainer>
            </Paper>
            {monitoredPropertyList?.count > PER_PAGE && (
              <Box id="pagination-container">
                <CustomPagination
                  count={count}
                  page={page}
                  handlePageChange={handlePageChange}
                />
              </Box>
            )}
          </Box>
        </>
      ) : (
        <>
          <Typography className="text-center text-danger">
            {monitoredPropertyList?.data?.message}
          </Typography>
        </>
      )}

      {/* table options */}
      <Menu
        // key={index}
        anchorEl={anchorEl}
        id="settings-menu"
        open={open}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.1))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        {!selectedRow?.free_subscription ? (
          <MenuItem
            key="view"
            onClick={() => handleFreeMonitoring(1)}
            className="setting-menu-item"
          >
            <Box className="setting-menu-box">
              <CheckCircleOutlineIcon />
              <Typography className="setting-menu-text">
                Enable Free Monitoring
              </Typography>
            </Box>
          </MenuItem>
        ) : (
          <MenuItem
            key="view"
            onClick={() => handleFreeMonitoring(0)}
            className="setting-menu-item"
          >
            <Box className="setting-menu-box">
              <NotInterestedIcon />
              <Typography className="setting-menu-text">
                Disable Free Monitoring
              </Typography>
            </Box>
          </MenuItem>
        )}

        <MenuItem
          key="view"
          onClick={() => setResendMailModal(true)}
          className="setting-menu-item"
        >
          <Box className="setting-menu-box">
            <EmailIcon />
            <Typography className="setting-menu-text">
              Re-Send to mail
            </Typography>
          </Box>
        </MenuItem>
      </Menu>

      {/* Free Monitoring enbale/disbale modals */}

      {subscribeModal && (
        <AlertDialog
          type={"Warning"}
          openPopup={true}
          onClose={true}
          onCloseFun={() => setSubscribeModal(false)}
          mesage={
            <>
              Are you sure you want to {subscribeAction ? "start" : "stop"} free
              monitoring for <b>{selectedRow?.address}?</b>
              {selectedRow?.free_subscription ? (
                <>
                  <br />
                  <br />
                  <b>
                    The user will be notified that the free monitoring service
                    will be discontinued.
                  </b>
                </>
              ) : null}
            </>
          }
          buttons={
            <>
              <Button
                className="popup_Btn success_popup_btn mx-2"
                onClick={subscribeFreeMonitor}
              >
                Yes
              </Button>
              <Button
                className="popup_Btn other_popup_btn mx-2"
                onClick={() => setSubscribeModal(false)}
              >
                No
              </Button>
            </>
          }
        />
      )}

      {getObjLength(subscribeFreeMonitoring) ? (
        <AlertDialog
          type={subscribeFreeMonitoring?.success ? "Success" : "Error"}
          openPopup={true}
          onClose={true}
          onCloseFun={closeSubscribeModal}
          mesage={
            subscribeFreeMonitoring?.success
              ? subscribeFreeMonitoring?.message
              : subscribeFreeMonitoring?.data?.message || "Something went wrong"
          }
        />
      ) : null}

      {/*Delete  Free Monitoring Properties modals */}

      {deletePropertyModal && (
        <AlertDialog
          type={"Warning"}
          openPopup={true}
          onClose={true}
          onCloseFun={() => setDeletePropertyModal(false)}
          mesage={
            <>
              {propertiesIDList?.length > 1 ? (
                <>
                  Are you sure you want to delete all the selected properties
                  from this list?
                </>
              ) : (
                <>
                  Are you sure you want to delete <b>{selectedRow?.address}</b>{" "}
                  property record from this list?
                </>
              )}
              {propertiesIDList?.length > 0 ? (
                <>
                  <br />
                  <br />
                  {propertiesIDList?.length > 1 ? (
                    <b>
                      The users whose property status is enabled will be
                      notified that the free monitoring service will be
                      discontinued.
                    </b>
                  ) : selectedRow?.free_subscription ? (
                    <b>
                      The user will be notified that the free monitoring service
                      will be discontinued.
                    </b>
                  ) : null}
                </>
              ) : null}
            </>
          }
          buttons={
            <>
              <Button
                className="popup_Btn success_popup_btn mx-2"
                onClick={deleteMonitoredProperty}
              >
                Yes
              </Button>
              <Button
                className="popup_Btn other_popup_btn mx-2"
                onClick={() => setDeletePropertyModal(false)}
              >
                No
              </Button>
            </>
          }
        />
      )}

      {getObjLength(deleteMonitoredPropertyData) ? (
        <AlertDialog
          type={deleteMonitoredPropertyData?.success ? "Success" : "Error"}
          openPopup={true}
          onClose={true}
          onCloseFun={closeSubscribeModal}
          mesage={
            deleteMonitoredPropertyData?.success
              ? deleteMonitoredPropertyData?.message
              : deleteMonitoredPropertyData?.data?.message ||
                "Something went wrong"
          }
        />
      ) : null}

      {/* Resend Reprot To Email */}
      {ResendMailModal && (
        <DialogBox
          openPopup={ResendMailModal}
          width={!openResendMailModal ? "md" : "sm"}
          title={!openResendMailModal ? "Resend Report to Email Address" : null}
          contentClassName={"bg-white"}
          setOpenPopup={setResendMailModal}
          onClose={() => closeDialogBox()}
          dividers={true}
          content={
            <ResendToEmailForm
              openResendMailModal={openResendMailModal}
              setOpenResendMailModal={setOpenResendMailModal}
              setResendMailModal={setResendMailModal}
              handleClose={closeDialogBox}
              selectedRow={selectedRow}
              onSubmit={onSubmit}
            />
          }
        />
      )}

      {getObjLength(resendReportMailData) ? (
        <AlertDialog
          type={resendReportMailData?.success ? "Success" : "Error"}
          openPopup={true}
          onClose={true}
          onCloseFun={closeResendReportModal}
          mesage={
            resendReportMailData?.success
              ? resendReportMailData?.message
              : resendReportMailData?.data?.message || "Something went wrong"
          }
        />
      ) : null}

      <Loader open={monitoredPropertyListLoader || riskManagementLoader} />
    </>
  );
};

export default AdminFreeMonitoringListTable;
