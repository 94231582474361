import { useState } from "react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button } from "@mui/material";

import { Loader } from "../../common/loader";
import { getObjLength } from "../../helpers/utils";
import PlainAlertMessage from "../../common/PlainAlertMessage";
import { propertyUsersAction } from "../../../redux/slices/admin/propertyUsers/propertyUsersSlice";

const ChangeUserEmailsToSupport = ({
  rowData = {},
  onClose = () => {},
  callListingAPI = () => {},
}) => {
  const dispatch = useDispatch();
  const [openPopup, SetOpenPopup] = useState(false);
  const { propertyUsersLoader, changeUserEmailsToSupportData } = useSelector(
    (state) => state.propertyUsers
  );
  const onPopupClose = (type) => {
    SetOpenPopup(false);
    dispatch(propertyUsersAction.fetchUserEmailsToSupportData({}));
    if (type !== "error") {
      callListingAPI();
      onClose();
    }
  };
  const handleContinueBtn = () => {
    dispatch(
      propertyUsersAction.changeUserEmailsToSupport({
        url: `admin/users/${rowData?.user_id}/has-no-email`,
        data: { has_no_email: !rowData?.has_no_email },
        token: true,
      })
    );
  };

  useEffect(() => {
    console.log(
      "changeUserEmailsToSupportData==>",
      changeUserEmailsToSupportData
    );
    if (getObjLength(changeUserEmailsToSupportData)) {
      SetOpenPopup(true);
    }
  }, [changeUserEmailsToSupportData]);

  return (
    <Box className="">
      {!changeUserEmailsToSupportData?.success &&
      !changeUserEmailsToSupportData?.error ? (
        <>
          <PlainAlertMessage
            type="Warning"
            onClose={true}
            onCloseFun={() => {
              onClose();
            }}
            message={
              rowData?.has_no_email
                ? `This will send emails back to the user's email address, not the support's. Do you wish to continue?`
                : `This will send emails to the support address, not the user's. Do you wish to continue?`
            }
            buttons={
              <>
                <Box className="mb-4 alert-modal-btn">
                  <Button
                    className="popup_Btn other_popup_btn mx-2"
                    onClick={() => onClose()}
                  >
                    No, Close
                  </Button>
                  <Button
                    className="popup_Btn success_popup_btn mx-2"
                    onClick={handleContinueBtn}
                  >
                    Yes, Continue
                  </Button>
                </Box>
              </>
            }
          />
        </>
      ) : null}

      {openPopup && getObjLength(changeUserEmailsToSupportData) && (
        <>
          {changeUserEmailsToSupportData?.success ? (
            <PlainAlertMessage
              type="Success"
              openPopup={openPopup}
              onClose={true}
              onCloseFun={() => onPopupClose("success")}
              message={changeUserEmailsToSupportData?.message}
              footer={false}
            />
          ) : (
            <PlainAlertMessage
              type="Error"
              openPopup={openPopup}
              onClose={true}
              onCloseFun={() => onPopupClose("error")}
              message={changeUserEmailsToSupportData?.data?.message}
              footer={false}
            />
          )}
        </>
      )}

      <Loader open={propertyUsersLoader} />
    </Box>
  );
};

export default ChangeUserEmailsToSupport;
