import { createSlice } from "@reduxjs/toolkit";

const intialPropertyUsersState = {
  propertyUsersLoader: false,
  propertyUsersData: {},
  propertyUserDataCount: 0,
  propertyUsersList: {},
  propertyUsersDeletedData: {},
  userProperties: {},
  securityPINResponse: {},
  addUserDetails: {},
  addUserLoader: false,
  upatedEmailResponse: {},
  upatedPhoneResponse: {},
  editDataLodaer: false,
  historyNotesData: {},
  historyNoteLoader: false,
  selectedProperty: {},
  notesLoader: false,
  noteDetails: {},
  deletePropertyUserDetails: {},
  deletePropertyUserLoader: false,
  deletePropertyLoader: false,
  deletePropertyData: {},
  editedUserLoader: false,
  editedUserData: {},
  cardListLoader: false,
  cardListData: {},
  deleteCardLoader: false,
  setDefaultCardData: {},
  deleteCardData: {},
  deleteNoteLoader: false,
  deleteNoteDetails: {},
  activateInactivateDetails: {},
  activateInactivateLoader: false,
  resendWelcomeEmailDetails: {},
  resendWelcomeEmailLoader: false,
  internalAdminDetails: {},
  internalAdminLoader: false,
  updateReferralCodeDetails: {},
  changeUserEmailsToSupportData: {},
};

const propertyUsers = createSlice({
  name: "propertyUsersDetails",
  initialState: intialPropertyUsersState,
  reducers: {
    getPropertyUsersData: (state, action) => {
      state.propertyUsersLoader = true;
      console.log("+++propertyUsersData slice", action.payload);
    },
    fetchPropertyUsersList: (state, action) => {
      console.log("fetchPropertyUsersData slice", action.payload);
      state.propertyUsersList = action.payload;
      state.propertyUserDataCount = action.payload?.count;
      state.propertyUsersLoader = false;
    },
    propertyUsersData: (state, action) => {
      state.propertyUsersLoader = true;
      console.log("propertyUsersData slice", action.payload);
    },
    fetchPropertyUsersData: (state, action) => {
      console.log("fetchPropertyUsersData slice", action.payload);
      state.propertyUsersLoader = false;
      state.propertyUsersData = action.payload;
    },
    userPropertiesData: (state, action) => {
      state.propertyUsersLoader = true;
      console.log("userPropertiesData slice", action.payload);
    },
    fetchUserPropertiesData: (state, action) => {
      console.log("fetchUserPropertiesData slice", action.payload);
      state.propertyUsersLoader = false;
      state.userProperties = action.payload;
    },
    getSecurityPIN: (state, action) => {
      state.propertyUsersLoader = true;
      console.log("userPropertiesData slice", action.payload);
    },
    fetchSecurityPINData: (state, action) => {
      console.log("fetchUserPropertiesData slice", action.payload);
      state.propertyUsersLoader = false;
      state.securityPINResponse = action.payload;
    },
    deletePropertyUser: (state, action) => {
      state.deletePropertyUserLoader = true;
      console.log("property user slice", action.payload);
    },
    setDeletePropertyUserDetails: (state, action) => {
      console.log("fetchpropertyuser data slice", action.payload);
      state.deletePropertyUserLoader = false;
      state.deletePropertyUserDetails = action?.payload;
    },
    deletePropertyUsersDetails(state, action) {
      state.propertyUsersLoader = true;
    },
    clearData: (state, action) => {
      console.log("Clear data");
      state.propertyUsersLoader = false;
      state.propertyUsersData = {};
      state.propertyUsersDeletedData = {};
      state.securityPINResponse = {};
    },
    createUser: (state, action) => {
      state.addUserLoader = true;
    },
    setCreatedUser: (state, action) => {
      state.addUserLoader = false;
      state.addUserDetails = action.payload;
    },
    getUpdatedEmail: (state, action) => {
      state.editDataLodaer = true;
      console.log("userPropertiesData email update slice", action.payload);
    },
    fetchUpdatedEmailData: (state, action) => {
      console.log("fetchUserPropertiesData slice", action.payload);
      state.editDataLodaer = false;
      state.upatedEmailResponse = action.payload;
    },
    getUpdatedPhone: (state, action) => {
      state.editDataLodaer = true;
      console.log("userPropertiesData email update slice", action.payload);
    },
    fetchUpdatedPhoneData: (state, action) => {
      console.log("fetchUserPropertiesData slice", action.payload);
      state.editDataLodaer = false;
      state.upatedPhoneResponse = action.payload;
    },
    clearEditData: (state, action) => {
      state.editDataLodaer = false;
      state.upatedEmailResponse = {};
      state.upatedPhoneResponse = {};
    },
    getUserHistory: (state, action) => {
      state.historyNoteLoader = true;
    },
    setUserHistory: (state, action) => {
      state.historyNotesData = action?.payload;
      state.historyNoteLoader = false;
    },
    clearHistoryAndNotes: (state, action) => {
      state.historyNotesData = {};
      state.historyNoteLoader = false;
    },
    setSelectedProperty: (state, action) => {
      state.selectedProperty = action?.payload;
    },
    addNote: (state, action) => {
      state.notesLoader = true;
    },
    setAddNote: (state, action) => {
      state.noteDetails = action?.payload;
      state.notesLoader = false;
    },
    getDeletePropertyData: (state, action) => {
      state.deletePropertyLoader = true;
    },
    fetchDeletePropertyData: (state, action) => {
      state.deletePropertyData = action?.payload;
      state.deletePropertyLoader = false;
    },
    clearDeletePropertyData: (state, action) => {
      state.deletePropertyData = {};
      state.deletePropertyLoader = false;
    },
    getEditedUserData: (state, action) => {
      state.editedUserLoader = true;
    },
    fetchEditedUserData: (state, action) => {
      state.editedUserData = action?.payload;
      state.editedUserLoader = false;
    },
    clearEditedUserData: (state, action) => {
      state.editedUserData = {};
      state.editedUserLoader = false;
    },
    getCardListData: (state, action) => {
      state.cardListLoader = true;
    },
    fetchCardListData: (state, action) => {
      state.cardListData = action?.payload;
      state.cardListLoader = false;
    },
    getSetDefaultCardData: (state, action) => {
      state.deleteCardLoader = true;
    },
    fetchSetDefaultCardData: (state, action) => {
      state.setDefaultCardData = action?.payload;
      state.deleteCardLoader = false;
    },
    getDeleteCardData: (state, action) => {
      state.deleteCardLoader = true;
    },
    fetchDeleteCardData: (state, action) => {
      state.deleteCardData = action?.payload;
      state.deleteCardLoader = false;
    },
    clearDeleteCardData: (state, action) => {
      state.deleteCardData = {};
      state.setDefaultCardData = {};
      state.cardListData = {};
      state.deleteCardLoader = false;
    },
    deleteNote: (state, action) => {
      state.deleteNoteLoader = true;
    },
    setDeleteNoteDetails: (state, action) => {
      state.deleteNoteDetails = action?.payload;
      state.deleteNoteLoader = false;
    },
    activateInactivateCustomer: (state, action) => {
      state.activateInactivateLoader = true;
    },
    setActivateInactivateDetails: (state, action) => {
      state.activateInactivateDetails = action?.payload;
      state.activateInactivateLoader = false;
    },
    resendWelcomeEmailData: (state, action) => {
      state.resendWelcomeEmailLoader = true;
    },
    fetchResendWelcomeEmailData: (state, action) => {
      state.resendWelcomeEmailDetails = action?.payload;
      state.resendWelcomeEmailLoader = false;
    },
    resetPasswordData: (state, action) => {
      state.resendWelcomeEmailLoader = true;
    },
    fetchResetPasswordData: (state, action) => {
      state.resetPasswordDetails = action?.payload;
      state.resendWelcomeEmailLoader = false;
    },
    internalAdminList: (state, action) => {
      state.internalAdminLoader = true;
    },
    fetchInternalAdminListData: (state, action) => {
      state.internalAdminDetails = action?.payload;
      state.internalAdminLoader = false;
    },
    updateReferralCode: (state, action) => {
      state.propertyUsersLoader = true;
    },
    fetchUpdateReferralCodeData: (state, action) => {
      state.updateReferralCodeDetails = action?.payload;
      state.propertyUsersLoader = false;
    },
    changeUserEmailsToSupport: (state, action) => {
      state.propertyUsersLoader = true;
    },
    fetchUserEmailsToSupportData: (state, action) => {
      state.changeUserEmailsToSupportData = action?.payload;
      state.propertyUsersLoader = false;
    },
  },
});
export const propertyUsersAction = propertyUsers.actions;
export default propertyUsers.reducer;
