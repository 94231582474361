import React, { Suspense, useEffect, useRef, useState } from "react";

import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import {
  LazyLoadComponent,
  LazyLoadImage,
} from "react-lazy-load-image-component";

import { Box, Button, Stack, Typography } from "@mui/material";

import CallUsNow from "../common/CallUsNow";
import AsSeenOnSection from "./AsSeenOnSection";
import logo from "../../assets/logo/equity.WebP";
import Testimonials from "../common/Testimonials";
import { decryptPayload } from "../helpers/utils";
import phoneTalk from "../../assets/phone_in_talk.svg";
import { Callto } from "../common/ContcatAdminSupport";
import EP_bg_logo from "../../assets/icons/EP_bg_logo.webp";
import FAQsAccordianDesign from "../common/AccordianDesign";
import PropertyRiskManagementForm from "./PropertyRiskManagementForm";
import { SUPPORT_PHONE, SUPPORT_PHONE_FORMAT } from "../helpers/constants";

import "./GenericLandingPage.scss";
import "../PropertyRiskManagement/LeadGenDesktop.scss";

const GenericLandingPage = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const path = window?.location?.pathname;
  const riskFormRef = useRef(null);
  const is_scrp_hp = localStorage.getItem("scrp_hp");
  let countyCode = decryptPayload(localStorage.getItem("county-code"));

  // Function to handle scrolling to the form
  const scrollToForm = () => {
    riskFormRef.current.scrollIntoView({ behavior: "smooth" });
    setTimeout(() => {
      window.scrollBy(0, -300);
    }, 600);
  };
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setIsScrolled(true); // Set navbar background to black
      } else {
        setIsScrolled(false); // Set navbar background to transparent
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="generaic_page_content bg-white">
      <section className="generic_bg_img ">
        <Stack
          direction={"row"}
          spacing={2}
          className={`header_content ${isScrolled && "bgblack"}`}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Link
            to={is_scrp_hp ? `/county-recorder/${countyCode}` : "/"}
            className="EP_logo"
          >
            <LazyLoadImage src={logo} width="250px" height="100%" alt="logo" />
          </Link>
          <Box className="contact_section">
            <div className="d-none d-md-block">
              <Typography className="help_texts">Need help?</Typography>
              <Typography className="contact_texts">
                Call us at:{" "}
                <Callto phone={`${SUPPORT_PHONE}`}>
                  <span className="text-white">{SUPPORT_PHONE_FORMAT}</span>
                </Callto>
              </Typography>
            </div>
            <div className="MobAction_btn ms-auto d-flex flex-row d-md-none">
              <CallUsNow
                hideEmail={true}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                renderText={
                  <Button className="mx-1 call_btn" variant="link">
                    <LazyLoadImage
                      src={phoneTalk}
                      width="24px"
                      height="24px"
                      alt="Phone Talk"
                    />
                  </Button>
                }
              />
            </div>
          </Box>
        </Stack>
        <div className="d-flex align-items-center first-section">
          <Container fluid className="text-white mx-150">
            <Row className="align-items-center" spacing={3}>
              <Col xs={12} sm={12} md={12} lg={6} xl={5}>
                <Stack direction={"column"} spacing={2}>
                  <Box className="top_section_spacing">
                    <h1 className="fw-700 mb-3 main-text">
                      Free Home Title Monitoring – No strings attached
                    </h1>

                    <p className="paragraph_text fw-400 mb-3 mt-1">
                      Our competitors charge $240/year for similar services, we
                      offer absolutely free monitoring. This service will notify
                      you immediately before any changes can be made to your
                      property.{" "}
                    </p>
                    {/* <p className="paragraph_text fw-400 mb-3 mt-1">
                      Whatever is on the page after you take the risk report
                      “Equity Guard”
                    </p> */}
                  </Box>
                </Stack>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={6}
                xl={7}
                className="text-center"
              >
                <div className="ep__img-block">
                  <Box
                    className="radio_img property_form property-risk-page-content lead_form"
                    ref={riskFormRef}
                  >
                    <PropertyRiskManagementForm
                      getFreeReport={true}
                      leadGen={true}
                      source={"Generic Host"}
                      host_path={path}
                    />
                  </Box>
                </div>
                <div className="ep__bg-logo">
                  <img className="" src={EP_bg_logo} alt={""} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>

      <section className="as_seen_on_section">
        <AsSeenOnSection />
      </section>

      <section className="alert_section  text-center mobile-secions-pt-0 bg-white">
        <Container fluid className="mx-150 it_works">
          <h3 className="how_its_works_title">How this works</h3>
          <Row className="steps_wrapper mt-4">
            <Col xs={12} sm={6} md={6} lg={4} xl={4} className="py-2">
              <Box className="info_blocks adjust_spacing">
                <div className="number_alignment">
                  <span className="steps mb-4">1</span>
                </div>
                <Typography className="header_text">
                  Search Your Address
                </Typography>
                <Typography className="mt-4 paragraph_text sec1">
                  We’ll match your address in our system and provide a free
                  1,100 data point risk assessment report.
                </Typography>
              </Box>
            </Col>
            <Col xs={12} sm={6} md={6} lg={4} xl={4} className="py-2">
              <Box className="info_blocks adjust_spacing">
                <div className="number_alignment">
                  <span className="steps mb-4">2</span>
                </div>
                <Typography className="header_text">
                  Sign up for free Equity Guard Monitoring
                </Typography>
                <Typography className="mt-4 paragraph_text sec2">
                  Our competitors charge up to $240/year for similar monitoring.
                  We are offering it completely free for a limited time.
                </Typography>
              </Box>
            </Col>
            <Col xs={12} sm={6} md={6} lg={4} xl={4} className="py-2">
              <Box className="info_blocks adjust_spacing">
                <div className="number_alignment">
                  <span className="steps mb-4 ">3</span>
                </div>
                <Typography className="header_text">That’s it!</Typography>
                <Typography className="mt-4 paragraph_text sec3">
                  We’ll let you know of any concerning alerts or changes to your
                  home title! We’ll do it before anything bad happens!
                </Typography>
              </Box>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="light_bg text-center py-4">
        <Container fluid className="mx-150 pt-5 pb-5">
          <h3 className="pp_title mb-4">Our clients trust us</h3>
          <Testimonials />
        </Container>
      </section>

      <section className="light_bg ">
        <Container fluid className="host_last_section text-center py-5">
          <h1 className="fw-700 mb-5 main-text">
            How can we offer this for free?
          </h1>
          <Row className="ep__promocode-sec">
            <div className="ep__promocode-wrap">
              <Box className="caption_sections ep__promocode-desc">
                <p className="captions fw-400 mb-3 mt-2">
                  Our mission is to provide equity protection for everyone. We
                  start with a comprehensive scan analyzing over 1,100 data
                  points to determine the risk for your specific property.
                </p>
                <p className="captions fw-400 mb-3 mt-2">
                  If your property is identified as "low" risk (about 40% of
                  properties) we recommend our first level of protection: Equity
                  Guard, which we're currently offering for free.
                </p>
                <p className="captions fw-400 mb-3 mt-2">
                  If you're identified as medium to high risk, then you should
                  consider our affordable Equity Protect service. We're the only
                  company with a patented process to lock down your title to
                  prevent equity theft & fraud before it happens.
                </p>
                <p className="captions fw-400 mb-3 mt-2">
                  Whatever your budget and needs, title monitoring is completely
                  free. It's a no brainer!{" "}
                </p>

                <Stack
                  className="buttons_section"
                  direction={{ xs: "column", sm: "row" }}
                >
                  <Button
                    className="risk_button success_popup_btn"
                    onClick={scrollToForm}
                  >
                    Get started with Free Title Monitoring
                  </Button>
                </Stack>
                <p className="call_on">
                  or call{" "}
                  <Callto phone={`${SUPPORT_PHONE}`}>
                    <span className="text-white">{SUPPORT_PHONE_FORMAT}</span>
                  </Callto>
                </p>
              </Box>
            </div>
          </Row>
        </Container>
      </section>
      {/* <section className="faq_wrapper">
        <Container fluid className="mx-150 pt-3 pb-5">
          <Suspense fallback={<div>Loading...</div>}>
            <LazyLoadComponent threshold={200}>
              <FAQsAccordianDesign />
            </LazyLoadComponent>
          </Suspense>
        </Container>
      </section> */}
    </div>
  );
};

export default GenericLandingPage;
