import React, { useEffect, useState } from "react";

import * as Yup from "yup";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Field, Form, Formik, useFormikContext } from "formik";

import CloseIcon from "@mui/icons-material/Close";
import { Box, Grid, IconButton, MenuItem, TextField } from "@mui/material";

import CustomDateRangePicker from "../../../common/CustomDateRangePicker";
import {
  getDateInMMDDYYYYFormat,
  getDateInYYYYMMDDFormat,
} from "../../../helpers/utils";
import { adminDashboardAction } from "../../../../redux/slices/admin/admin-dashboard/adminDashboardSlice";

const validationSchema = Yup.object().shape({
  duration: Yup.string().required("Duration is required"),
  dateRange: Yup.mixed().when("duration", {
    is: "Custom Date",
    then: Yup.object().shape({
      startDate: Yup.date().required("Start date is required"),
      endDate: Yup.date()
        .required("End date is required")
        .min(Yup.ref("startDate"), "End date must be after start date"),
    }),
    otherwise: Yup.mixed().test(
      "is-date-range",
      "Date range is required",
      (value) => value !== null && value !== undefined
    ),
  }),
});

const DateFilterModal = ({
  closePopup = () => {},
  dateRange,
  setDateRange = () => {},
  setDateRangeFormat = () => {},
  dateOptions,
  setDateOptions = () => {},
}) => {
  const dispatch = new useDispatch();
  const [isReset, setIsReset] = useState(false);
  const durationList = [
    { name: "Today" },
    // { name: "Yesterday" },
    { name: "This Week" },
    { name: "This Month" },
    { name: "Last Month" },
    { name: "Last 3 Months" },
    { name: "Last 6 Months" },
    { name: "This Year" },
    { name: "Custom Date" },
  ];

  const getDateRange = (duration) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const endDate = new Date(today);

    switch (duration) {
      case "Today":
        return { startDate: today, endDate };
      case "Yesterday":
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);
        return { startDate: yesterday, endDate: yesterday };
      case "This Week":
        const thisWeekStart = new Date(today);
        thisWeekStart.setDate(thisWeekStart.getDate() - thisWeekStart.getDay());
        return { startDate: thisWeekStart, endDate };
      case "This Month":
        const thisMonthStart = new Date(
          today.getFullYear(),
          today.getMonth(),
          1
        );
        return { startDate: thisMonthStart, endDate };
      case "Last Month":
        const lastMonthStart = new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          1
        );
        const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);
        return { startDate: lastMonthStart, endDate: lastMonthEnd };
      case "Last 3 Months":
        const threeMonthsAgo = new Date(today);
        threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
        return { startDate: threeMonthsAgo, endDate };
      case "Last 6 Months":
        const sixMonthsAgo = new Date(today);
        sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
        return { startDate: sixMonthsAgo, endDate };
      case "This Year":
        const thisYearStart = new Date(today.getFullYear(), 0, 1);
        return { startDate: thisYearStart, endDate };
      case "Custom Date":
        return { startDate: today, endDate };
      default:
        return null;
    }
  };
  const AutoDateRange = () => {
    const { values, setFieldValue } = useFormikContext();

    useEffect(() => {
      if (dateOptions !== "This Month" && isReset) {
        setIsReset(false);
      }
      if (!isReset) {
        const newDateRange = getDateRange(values.duration);
        setDateOptions(values?.duration);

        setFieldValue("dateRange", newDateRange);
      }
    }, [values.duration, setFieldValue]);

    return null;
  };

  const handleReset = (setFieldValue) => {
    if (dateOptions !== "This Month") {
      dispatch(
        adminDashboardAction.getAdminDashboardData({
          url: "admin/admin-states",
          data: {},
          token: true,
        })
      );
    }
    setDateRange();
    setDateRangeFormat();
    setDateOptions("This Month");
    setFieldValue("This Month");
    setIsReset(true);

    closePopup(false);
  };

  const handleSubmit = (from_date, to_date, dateFormat) => {
    const data = {
      from_date: from_date,
      to_date: to_date,
    };
    setDateRange(data);
    dispatch(
      adminDashboardAction.getAdminDashboardData({
        url: "admin/admin-states",
        data,
        token: true,
      })
    );
    setDateRangeFormat(dateFormat);
    closePopup(false);
  };

  return (
    <Box>
      <IconButton
        aria-label="toggle password visibility"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          closePopup(false);
        }}
        sx={{ position: "absolute", top: "6px", right: 0 }}
      >
        <CloseIcon sx={{ color: "#9CB8A6" }} />
      </IconButton>
      <Formik
        initialValues={{
          duration: dateOptions || "This Month",
          dateRange: dateRange || {
            startDate: new Date(),
            endDate: new Date(),
          },
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          let from_date = getDateInYYYYMMDDFormat(
            values?.dateRange?.startDate,
            "-"
          );
          let to_date = getDateInYYYYMMDDFormat(
            values?.dateRange?.endDate,
            "-"
          );
          let dateFormat = {
            from_date: getDateInMMDDYYYYFormat(
              values?.dateRange?.startDate,
              "/"
            ),
            to_date: getDateInMMDDYYYYFormat(values?.dateRange?.endDate, "/"),
          };
          console.log("Submitted values:", from_date, to_date);
          handleSubmit(from_date, to_date, dateFormat);
          // }
          setTimeout(() => {
            setSubmitting(false);
          }, 400);
        }}
      >
        {({ values, errors, touched, setFieldValue }) => {
          return (
            <Form>
              <AutoDateRange />
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  md={6}
                  px={1}
                  pb={1}
                  sx={{ marginTop: "23px" }}
                >
                  <Field
                    as={TextField}
                    select
                    name="duration"
                    label="Duration"
                    fullWidth
                    error={touched.duration && errors.duration}
                    helperText={touched.duration && errors.duration}
                  >
                    {durationList.map((option) => (
                      <MenuItem key={option.name} value={option.name}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6} px={1} pb={1}>
                  <CustomDateRangePicker
                    rangeValue={values.dateRange}
                    sx={{ paddingRight: "0px" }}
                    onChange={(range) => {
                      const data = {
                        startDate: range.startDate._d,
                        endDate: range.endDate._d,
                      };
                      setFieldValue("dateRange", data);
                    }}
                    onClear={() => {
                      setFieldValue("dateRange", null);
                      closePopup(false);
                    }}
                    label="Date Range"
                    name="dateRange"
                    values={values.dateRange}
                    touched={touched.dateRange}
                    errors={touched.dateRange && Boolean(errors.dateRange)}
                    disabled={values.duration !== "Custom Date"}
                  />
                </Grid>
                <Grid xs={12} md={12} px={1} pb={1}>
                  <div className="text-center createAcBtn mt-5">
                    <Button
                      type="submit"
                      className="popup_Btn success_popup_btn"
                      variant="contained"
                    >
                      Submit
                    </Button>
                    <Button
                      onClick={() => handleReset(setFieldValue)}
                      className="popup_Btn mx-2 other_popup_btn"
                      variant="contained"
                    >
                      Reset
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default DateFilterModal;
