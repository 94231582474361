import { createSlice } from "@reduxjs/toolkit";

const intialAdminDashboardState = {
  adminDashboardDetails: {},
  adminDashboardLoader: false,
  subscriptionModuleFilterData: {},
  usersModuleFilterData: {},
  propertiesFilterData: {},
  monitoringPropertiesFilterData: {},
};

const adminDashboard = createSlice({
  name: "adminDashboardDetails",
  initialState: intialAdminDashboardState,
  reducers: {
    getAdminDashboardData: (state, action) => {
      state.adminDashboardLoader = true;
      console.log("AdminDashboard slice", action.payload);
    },
    fetchAdminDashboardData: (state, action) => {
      console.log("AdminDashboard slice", action.payload);
      state.adminDashboardLoader = false;
      state.adminDashboardDetails = action.payload;
    },
    getSubscriptionModuleFilterData: (state, action) => {
      console.log("AdminDashboard slice", action.payload);
      state.subscriptionModuleFilterData = action.payload;
    },
    getPropertiesFilterData: (state, action) => {
      console.log("AdminDashboard slice", action.payload);
      state.propertiesFilterData = action.payload;
    },
    getUsersModuleFilterData: (state, action) => {
      console.log("AdminDashboard slice", action.payload);
      state.usersModuleFilterData = action.payload;
    },
    getMonitoringPropertiesFilterData: (state, action) => {
      console.log("AdminDashboard slice", action.payload);
      state.monitoringPropertiesFilterData = action.payload;
    },
    clearTempFilterData: (state, action) => {
      state.usersModuleFilterData = {};
      state.subscriptionModuleFilterData = {};
    },
  },
});

export const adminDashboardAction = adminDashboard.actions;
export default adminDashboard.reducer;
