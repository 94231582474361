import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import AdminLogsListTable from "./AdminLogListTable";
import { Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

const AdminLogsList = () => {
  const params = useParams();
  const navigate = useNavigate();

  const goTOUserModule = () => {
    navigate(`/admin-users/?from=logs&user_id=${params?.user_id}`);
  };
  return (
    <>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant="h6" className="title">
          Logs List
        </Typography>
        {params?.user_id && (
          <Button
            type="submit"
            variant="contained"
            className="popup_Btn success_popup_btn"
            onClick={goTOUserModule}
          >
            Back
          </Button>
        )}
      </Stack>
      <Box
        sx={{
          mt: 2,
        }}
      >
        <AdminLogsListTable />
      </Box>
    </>
  );
};

export default AdminLogsList;
