import React from "react";

import { Col, Container, Row } from "react-bootstrap";

const FirstCommonBlogSection = ({ title, mainImg }) => {
  return (
    <div className="first_section_div">
      <section className="d-flex first-section">
        <Container fluid className="text-white main_container">
          <Row className=" justify-content-center">
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={10}
              xl={8}
              className="header_texts"
            >
              <>
                <p className="blog_header mb-2" style={{ color: "#0395FF" }}>
                  Blog <span className="blog_seperator">|</span> October 19,
                  2024
                </p>
                <h1 className="fw-700 mb-3 main-text">{title}</h1>
              </>
            </Col>
          </Row>
        </Container>
      </section>
      <div
        className={`d-flex justify-content-between align-items-start text-left d-sm-pt-0 pt-2 img_div ${mainImg}`}
      />
    </div>
  );
};

export default FirstCommonBlogSection;
