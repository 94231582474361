import { call, put, takeLatest } from "redux-saga/effects";

import { postAPIPayload } from "../../../../apis/postApi";
import { adminDashboardAction } from "../../../slices/admin/admin-dashboard/adminDashboardSlice";

export function* fetchAdminDashboardDetailsSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("response==>.", response);
  yield put(adminDashboardAction.fetchAdminDashboardData(response));
}

export function* watchAdminDashboardSagaAsync() {
  yield takeLatest(
    adminDashboardAction.getAdminDashboardData,
    fetchAdminDashboardDetailsSaga
  );
}
