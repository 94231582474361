import { useCallback } from "react";
import React, { useState } from "react";
import { useEffect, useRef } from "react";

import { Link } from "react-router-dom";
import { Cropper } from "react-cropper";
import PhoneInput from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import { Search } from "@mui/icons-material";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import DeleteIcon from "@mui/icons-material/Delete";
import TableContainer from "@mui/material/TableContainer";
import ModeOutlinedIcon from "@mui/icons-material/ModeOutlined";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import AddToPhotosOutlinedIcon from "@mui/icons-material/AddToPhotosOutlined";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import {
  Avatar,
  Button,
  Card,
  Collapse,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  OutlinedInput,
  Stack,
  TextField,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";

import FileUpload from "../../common/FileUpload";
import DeleteModal from "../../common/DeleteModal";
import AlertDialog from "../../common/AlertDialog";
import TextTruncate from "../../common/TextTruncate";
import EditServiceProvide from "./EditServiceProvide";
import SettingsIconSVG from "../../common/SettingsIconSVG";
import CustomLinkWrapper from "../../common/CustomLinkWrapper";
import CustomDateRangePicker from "../../common/CustomDateRangePicker";
import TableHeaderForSorting from "../../common/TableHeaderForSorting";
import {
  mobileFilterResetBtnStyle,
  mobileFilterResultStyle,
} from "../AddLeads";
import { closeDeleteIconStyle } from "../../Service-Provider/signup/SPSignupForm";
import pdfPlaceholder from "../../../assets/service-provider/pdf_placeholder.webp";
import CustomPagination from "../../common/customDesignedComponents/CustomPagination";
import wordDocPlaceholder from "../../../assets/service-provider/document_placeholder.webp";
import {
  COUNTRY_CODE,
  servicerProvidersjsonLastStepOptions,
} from "../../helpers/constants";
import { seviceProvidersAction } from "../../../redux/slices/admin/serviceProviders/seviceProvidersSlice";
import {
  createFileUrl,
  getDateInYYYYMMDDFormat,
  getFormatedPhoneNumber,
  getLocalFormatedDate,
  getObjLength,
} from "../../helpers/utils";
import PlainAlertMessage from "../../common/PlainAlertMessage";
import DialogBox from "../../common/DialogBox";
import HistoryServiceAgreements from "./HistoryServiceAgreements";

function Row({
  row,
  handleEditButton,
  handleDeleteButton,
  page,
  index,
  handleCollapsibleRow,
  isCollapsible = false,
  getProviderActivate,
  handleMenuClick = () => {},
}) {
  return (
    <React.Fragment>
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={(page - 1) * 10 + index + 1}
        onClick={(e) => {
          handleCollapsibleRow(row);
          e.stopPropagation();
        }}
        className="cursor-pointer"
        sx={{ backgroundColor: isCollapsible ? "rgba(3, 149, 255, 0.07)" : "" }}
      >
        <TableCell align="left">{(page - 1) * 10 + index + 1}</TableCell>
        <TableCell align="left" onClick={(e) => e.stopPropagation()}>
          <span className="company-logo">
            {/* {row?.company_logo ? ( */}
            <Avatar src={row?.company_logo} />
            {/* ) : (
              <Avatar
                {...stringAvatar(
                  capitalizeFirstCharInWord(
                    `${row?.first_name} ${row?.last_name}`
                  )
                )}
              />
            )} */}
          </span>
        </TableCell>

        <TableCell align="left" className="text-capitalize">
          {row.first_name + " " + row.last_name}
        </TableCell>
        <TableCell align="left" sx={{ wordBreak: "break-all" }}>
          {row.email}
        </TableCell>
        <TableCell align="left" sx={{ wordBreak: "break-all" }}>
          {/* {row.phone_number} */}
          {getFormatedPhoneNumber(row?.phone_number) || row?.phone_number}
        </TableCell>
        <TableCell align="left">
          {row?.service_provider_type?.type
            ? row?.service_provider_type?.type?.split("/")?.join(" / ")
            : "-"}
        </TableCell>
        <TableCell align="left">
          {row.company_info?.company_name
            ? row.company_info?.company_name
            : "-"}
        </TableCell>
        <TableCell align="left">
          {row.is_active === 0 && row?.status === "pending" ? (
            // <span className="text-danger">Pending</span>
            <Tooltip
              title="Activate Provider"
              TransitionComponent={Zoom}
              placement="top"
              arrow
            >
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  getProviderActivate(row);
                }}
                className="px-2 error_popup_btn text-white"
                sx={{ fontSize: "12px" }}
              >
                Pending
              </Button>
            </Tooltip>
          ) : row?.status === "active" ? (
            <span className="text-success text-capitalize">{row?.status}</span>
          ) : (
            <span className="text-danger text-capitalize">{row?.status}</span>
          )}
        </TableCell>
        <TableCell align="left" width={120}>
          {row?.created_at ? getLocalFormatedDate(row?.created_at) : "-"}
        </TableCell>
        <TableCell align="left" width={90}>
          {row?.created_at ? getLocalFormatedDate(row?.updated_at) : "-"}
        </TableCell>
        <TableCell align="left">
          <Stack direction="row" spacing={0}>
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                handleMenuClick(event, row);
              }}
              size="small"
              className="settings-button"
              aria-controls="settings-menu"
              aria-haspopup="true"
              aria-expanded={"true"}
            >
              <SettingsIconSVG color={"#15BE53"} />
            </IconButton>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => handleCollapsibleRow(row)}
            >
              {isCollapsible ? (
                <KeyboardArrowUpIcon style={{ color: " #15BE53" }} />
              ) : (
                <KeyboardArrowDownIcon style={{ color: " #15BE53" }} />
              )}
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>

      {/* collapsible row */}
      <TableRow
        className="collapsible-row"
        sx={{ backgroundColor: "rgba(3, 149, 255, 0.07)" }}
      >
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0 }}
          colSpan={1}
        ></TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={2}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "70px",
              }}
            >
              <Stack direction="column">
                <Typography
                  variant="h6"
                  className="content-heading"
                  component="div"
                >
                  Address
                </Typography>
                <Typography className="content-text">
                  {row?.address?.address}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={1}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "70px",
              }}
            >
              <Stack direction="column">
                <Typography
                  variant="h6"
                  className="content-heading"
                  component="div"
                >
                  City
                </Typography>
                <Typography className="content-text text-capitalize">
                  {row?.address?.city || "-"}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={1}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "70px",
              }}
            >
              <Stack direction="column">
                <Typography
                  variant="h6"
                  className="content-heading"
                  component="div"
                >
                  State
                </Typography>
                <Typography className="content-text">
                  {row?.address?.state || "-"}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={1}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "70px",
              }}
            >
              <Stack direction="column">
                <Typography
                  variant="h6"
                  className="content-heading"
                  component="div"
                >
                  Zip
                </Typography>
                <Typography className="content-text">
                  {row?.address?.zip || "-"}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={2}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "70px",
              }}
            >
              <Stack direction="column">
                <Typography
                  variant="h6"
                  className="content-heading"
                  component="div"
                >
                  Last Step
                </Typography>
                <Typography className="content-text">
                  {row?.json_last_step?.step_key
                    ? row?.json_last_step?.step_key
                    : "-"}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={1}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "70px",
              }}
            >
              <Stack direction="column">
                <Typography
                  variant="h6"
                  className="content-heading"
                  component="div"
                >
                  Created By
                </Typography>
                <Typography className="content-text text-capitalize">
                  {row?.created_by?.id === 1 ? (
                    "Super Admin"
                  ) : row?.created_by && row?.created_by?.id !== row?.id ? (
                    <Tooltip
                      title="Click here to see user's details."
                      TransitionComponent={Zoom}
                      placement="top"
                      arrow
                    >
                      <Link
                        className="text-decoration-none "
                        to={"/admins/" + row?.created_by?.id}
                      >
                        {row?.created_by?.full_name}
                      </Link>
                    </Tooltip>
                  ) : row?.id === row?.created_by?.id ? (
                    <>{row?.full_name}</>
                  ) : (
                    "-"
                  )}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={2}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "70px",
              }}
            >
              <Stack direction="column">
                <Typography
                  variant="h6"
                  className="content-heading"
                  component="div"
                >
                  Service Agreement
                </Typography>
                {row?.service_agreement ? (
                  <CustomLinkWrapper
                    link={row?.service_agreement}
                    title={
                      <TextTruncate
                        text={
                          row?.service_agreement?.split("/")?.[
                            row?.service_agreement?.split("/")?.length - 1
                          ]
                        }
                        maxLength={20}
                      />
                    }
                    classes="content-text"
                  />
                ) : (
                  "-"
                )}
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      <TableRow
        className="collapsible-row"
        sx={{ backgroundColor: "rgba(3, 149, 255, 0.07)" }}
      >
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0 }}
          colSpan={1}
        ></TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={2}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "70px",
              }}
            >
              <Stack direction="column">
                <Typography
                  variant="h6"
                  className="content-heading"
                  component="div"
                >
                  Office Address
                </Typography>
                <Typography className="content-text text-capitalize">
                  {row.company_info?.ofc_address
                    ? row.company_info?.ofc_address
                    : "-"}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={1}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "70px",
              }}
            >
              <Stack direction="column">
                <Typography
                  variant="h6"
                  className="content-heading"
                  component="div"
                >
                  Office City
                </Typography>
                <Typography className="content-text text-capitalize">
                  {row?.company_info?.ofc_city
                    ? row?.company_info?.ofc_city
                    : "-"}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={1}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "70px",
              }}
            >
              <Stack direction="column">
                <Typography
                  variant="h6"
                  className="content-heading"
                  component="div"
                >
                  Office State
                </Typography>
                <Typography className="content-text text-capitalize">
                  {row?.company_info?.ofc_state
                    ? row?.company_info?.ofc_state
                    : "-"}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={1}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "70px",
              }}
            >
              <Stack direction="column">
                <Typography
                  variant="h6"
                  className="content-heading"
                  component="div"
                >
                  Office Zip
                </Typography>
                <Typography className="content-text">
                  {row?.company_info?.ofc_zip
                    ? row?.company_info?.ofc_zip
                    : "-"}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={2}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "70px",
              }}
            >
              <Stack direction="column">
                <Typography
                  variant="h6"
                  className="content-heading"
                  component="div"
                >
                  Office Suite
                </Typography>
                <Typography className="content-text text-capitalize">
                  {row?.company_info?.ofc_suite
                    ? row?.company_info?.ofc_suite
                    : "-"}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={
            row?.json_last_step?.step_key === "SEND_WELCOME_EMAIL" ? 1 : 5
          }
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "70px",
              }}
            >
              <Stack direction="column">
                <Typography
                  variant="h6"
                  className="content-heading"
                  component="div"
                >
                  Office Phone
                </Typography>
                <Typography className="content-text">
                  {row.company_info?.ofc_phone
                    ? getFormatedPhoneNumber(row.company_info?.ofc_phone) ||
                      row.company_info?.ofc_phone
                    : "-"}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
        {row?.json_last_step?.step_key === "SEND_WELCOME_EMAIL" ? (
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              paddingLeft: 0,
            }}
            colSpan={2}
          >
            <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  margin: 1,
                  minHeight: "70px",
                }}
              >
                <Stack direction="column">
                  <Typography
                    variant="h6"
                    className="content-heading"
                    component="div"
                  >
                    More details..
                  </Typography>
                  <Link
                    className="text-decoration-none "
                    to={"/admin-users/" + row?.user_id}
                  >
                    <Typography className="content-text">
                      Go to Dashboard
                    </Typography>
                  </Link>
                </Stack>
              </Box>
            </Collapse>
          </TableCell>
        ) : null}
      </TableRow>
    </React.Fragment>
  );
}

const ServiceProvidersTable = ({
  providersCount,
  columns,
  rowsData,
  // getProviderActivate,
  providerID,
  latestData,
}) => {
  const dispatch = useDispatch();
  const cropperRef = useRef();

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [selectedType, setSelectedType] = useState("all");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedProviderType, setSelectedProviderType] = useState();
  const [jsonLastStep, setJsonLastStep] = useState("");
  const [searchedTerm, setSearchedTerm] = useState("");
  const [toggleFilterOptions, setToggleFilterOptions] = useState(false);
  const [callAPI, setCallAPI] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isEditServiceProviderModalOpen, setIsEditServiceProviderModalOpen] =
    useState(false);
  const [
    isDeleteServiceProviderModalOpen,
    setIsDeleteServiceProviderModalOpen,
  ] = useState(false);
  const [payload, setPayload] = useState({});
  const [alertMsg, setAlertMsg] = useState(false);
  const [successMsg, setSuccessMsg] = useState();
  const [errMsg, setErrMsg] = useState();
  const [selectedCollapsibleRow, setSelectedCollapsibleRow] = useState(null);
  const [isCollapsibleRow, setIsCallapsibleRow] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [directionFlow, setDirectionFlow] = useState(true);
  const [selectedColumn, setSelectedColumn] = useState(10); // updated_at key for sorting
  const [selectedDateType, setSelectedDateType] = useState("created_at");
  const [
    isActivateDeactivateServiceProviderModalOpen,
    setIsActivateDeactivateServiceProviderModalOpen,
  ] = useState(null);
  const [actionPerformed, setActionPerformed] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isUploadAgreementModalOpen, setIsUploadAgreementModalOpen] =
    useState(false);
  const [isHistoryAgreementModalOpen, setIsHistoryAgreementModalOpen] =
    useState(false);
  const [isUploadLogoModalOpen, setIsUploadLogoModalOpen] = useState(false);
  const [uploadedServiceAgreement, setUploadedServiceAgreement] =
    useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const open = Boolean(anchorEl);

  const { professionalsList } = useSelector(
    (state) => state.serviceProviderSignup
  );
  const {
    deleteServiceProvidersRes,
    actionLoader,
    activateServiceProvidersRes,
    activateDeactivateServiceProvider,
    UploadedServiceAgreementDetails,
    serviceAgreementLoader,
    uploadedCompanyLogoDetails,
    companyLogoLoader,
  } = useSelector((state) => state.seviceProviders);

  const [page, setPage] = useState(1);
  const PER_PAGE = 10;

  const count = Math.ceil(providersCount / PER_PAGE);
  const _DATA = rowsData;

  const handleMenuClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedCollapsibleRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const pageChangeHandler = (e, p) => {
    setPage(p);
    setPayload((prevState) => ({ ...prevState, offset: (p - 1) * PER_PAGE }));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleFilterList = (e) => {
    e.preventDefault();
    if (
      searchedTerm ||
      selectedProviderType ||
      selectedStatus ||
      jsonLastStep ||
      selectedDateRange
    ) {
      setPage(1);
      setPayload((prevState) => ({
        ...prevState,
        type: selectedType || undefined,
        value: searchedTerm || undefined,
        service_provider_type: selectedProviderType || undefined,
        is_active: selectedStatus || undefined,
        json_last_step: jsonLastStep || undefined,
        from_date:
          selectedType === "created_at" || selectedDateType === "created_at"
            ? selectedDateRange
              ? getDateInYYYYMMDDFormat(new Date(selectedDateRange?.startDate))
              : undefined
            : undefined,
        to_date:
          selectedType === "created_at" || selectedDateType === "created_at"
            ? selectedDateRange
              ? getDateInYYYYMMDDFormat(new Date(selectedDateRange?.endDate))
              : undefined
            : undefined,
        modified_from_date:
          selectedType === "updated_at" || selectedDateType === "updated_at"
            ? selectedDateRange
              ? getDateInYYYYMMDDFormat(new Date(selectedDateRange?.startDate))
              : undefined
            : undefined,
        modified_to_date:
          (selectedType === "updated_at" ||
            selectedDateType === "updated_at") &&
          selectedDateRange
            ? getDateInYYYYMMDDFormat(new Date(selectedDateRange?.endDate))
            : undefined,
        limit: PER_PAGE,
        offset: 0,
      }));

      setCallAPI(true);
    }
  };

  const resetAllFilterOptions = () => {
    setSelectedType("all");
    setSearchedTerm("");
    setSelectedStatus("");
    setSelectedProviderType("");
    setJsonLastStep("");
    setSelectedDateRange(null);
    setSelectedDateType("created_at");
    if (callAPI) {
      setPayload({ limit: PER_PAGE, offset: 0 });
      setCallAPI(false);
      setPage(1);
      setDirectionFlow(true);
      setSelectedColumn(9);
    }
  };

  // for sorting
  const handleSorting = (column) => {
    setDirectionFlow(!directionFlow);
    console.log(column, "Sorting function called", payload);
    console.log("Sorting flow", directionFlow);
    setPayload((prevState) => ({
      ...prevState,
      order_by_column: column,
      sorting_order: !directionFlow ? "desc" : "asc",
    }));
  };

  const handleActivateDeactivateServiceProvider = (selectedServiceProvider) => {
    console.log("selectedServiceProvider", selectedServiceProvider);
    if (selectedServiceProvider?.id) {
      let URL = "";
      if (actionPerformed === "activate") {
        URL = `admin/service-provider/${selectedServiceProvider?.id}/active`;
      } else {
        URL = `admin/service-provider/${selectedServiceProvider?.id}/inactive`;
      }

      dispatch(
        seviceProvidersAction.activateDeactivateServiceProvider({
          url: URL,
          data: {},
          token: true,
        })
      );
    }
  };

  const onSuccess = () => {
    setAlertMsg(false);
    dispatch(seviceProvidersAction.clearData({}));
    callListAPI();
  };

  const callListAPI = useCallback(
    (url) => {
      let finalURl = "admin/service-provider/get-service-providers";
      dispatch(
        seviceProvidersAction.getSeviceProvidersData({
          // url: url ? url : finalURl,
          url: finalURl,
          data: {
            type: payload?.type,
            value: payload?.value,
            service_provider_type: payload?.service_provider_type,
            status: payload?.is_active,
            json_last_step: payload?.json_last_step,
            from_date: payload?.from_date,
            to_date: payload?.to_date,
            modified_from_date: payload?.modified_from_date,
            modified_to_date: payload?.modified_to_date,
            limit: PER_PAGE,
            offset: payload?.offset || 0,
            order_by_column: payload?.order_by_column || "updated_at",
            sorting_order: payload?.sorting_order || "desc",
          },
          token: true,
        })
      );
    },
    [
      dispatch,
      payload?.type,
      payload?.value,
      payload?.service_provider_type,
      payload?.is_active,
      payload?.json_last_step,
      payload?.from_date,
      payload?.to_date,
      payload?.modified_from_date,
      payload?.modified_to_date,
      payload?.offset,
      payload?.order_by_column,
      payload?.sorting_order,
    ]
  );

  const getProviderActivate = (data) => {
    if (data?.id) {
      dispatch(
        seviceProvidersAction.getSeviceProvidersActivate({
          url: "admin/service-provider/activate/" + data?.id,
          token: true,
        })
      );
    }
  };

  // get cropped image
  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      setCroppedImage(
        cropperRef.current?.cropper.getCroppedCanvas().toDataURL()
      );
    }
  };

  // when user select the image
  const onChange = async (files) => {
    const reader = new FileReader();
    reader.onload = () => {
      setSelectedFile(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const handleUploadingCompanyLogo = () => {
    if (croppedImage) {
      console.log("Cropped Image", croppedImage);
      dispatch(
        seviceProvidersAction.uploadCompanyLogo({
          url: `admin/${selectedCollapsibleRow?.user_id}/upload-logo`,
          data: {
            company_logo: croppedImage,
          },
          token: true,
        })
      );
    }
  };

  useEffect(() => {
    callListAPI();
  }, [dispatch, callListAPI]);

  useEffect(() => {
    if (getObjLength(activateServiceProvidersRes)) {
      setAlertMsg(true);
      if (activateServiceProvidersRes?.success) {
        setSuccessMsg(activateServiceProvidersRes?.message);
        setErrMsg("");
      } else {
        setSuccessMsg("");
        setErrMsg(activateServiceProvidersRes?.data?.message);
      }
    }
  }, [activateServiceProvidersRes]);

  // handle open Edit modal
  const handleEditButton = (selectedItem) => {
    setIsEditServiceProviderModalOpen(true);
    setSelectedItem(selectedItem);
  };

  // handle open delete modal
  const handleDeleteButton = (selectedItem) => {
    setIsDeleteServiceProviderModalOpen(true);
    setSelectedItem(selectedItem);
  };

  //   handleClosing delete modal
  const handleCloseDeleteModal = () => {
    setIsDeleteServiceProviderModalOpen(false);
    setSelectedItem(null);
    dispatch(seviceProvidersAction.clearData({}));
  };

  // dispatch action to delete providers
  const handleDeleteServiceProvider = () => {
    if (selectedItem && selectedItem.id) {
      dispatch(
        seviceProvidersAction.getDeleteSeviceProvidersData({
          url: `admin/service-provider/delete/${selectedItem?.id}`,
          data: {},
          token: true,
        })
      );
    }
  };

  const handleCollapsibleRow = (row) => {
    if (selectedCollapsibleRow?.id === row?.id && isCollapsibleRow) {
      setIsCallapsibleRow(false);
      return;
    }
    setIsCallapsibleRow(true);
    setSelectedCollapsibleRow(row);
  };

  // handle upload service agreement for the service provide
  const handleUploadServiceAgreement = () => {
    if (
      selectedCollapsibleRow &&
      selectedCollapsibleRow?.id &&
      uploadedServiceAgreement
    ) {
      const formData = new FormData();
      formData.append("user_id", selectedCollapsibleRow?.id);
      formData.append("document", uploadedServiceAgreement);

      dispatch(
        seviceProvidersAction.uploadServiceAgreement({
          url: `admin/service-provider/upload-service-agreement`,
          data: formData,
          token: true,
          isFile: true,
        })
      );
    }
  };

  return (
    <>
      {/* Filtering Component Section */}
      <Tooltip title="Filter options" placement="right-start">
        <Box
          id="filter"
          onClick={() => setToggleFilterOptions(!toggleFilterOptions)}
        >
          <h3 className="filter-text">Filter your results</h3>
          <ArrowDropDownIcon
            className="filter-arrow"
            sx={{ ms: 1, fontSize: "20px" }}
          />
        </Box>
      </Tooltip>
      {/* Filtering Options */}
      <Box
        sx={{
          // overflow: "hidden",
          // overflowX: "auto",
          mb: 1.5,
        }}
      >
        {toggleFilterOptions && (
          <>
            {/* for mobile view */}
            <Box className="d-block d-md-none">
              <Grid
                container
                className="bg-white d-flex justify-content-around align-items-center px-4"
                spacing={2}
                pb={2}
                pt={2}
              >
                <Grid item xs={6} sm={6} md={4} lg={2}>
                  <Typography className="search-text">Search By</Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={2}>
                  <FormControl className="filter-select-one w-100">
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="All Types"
                      value={selectedType}
                      onChange={(e) => {
                        console.log("selected values==>", e.target.value);
                        setSelectedType(e.target.value);
                        setSearchedTerm("");
                        setSelectedStatus("");
                        setSelectedProviderType("");
                        setJsonLastStep("");
                        setSelectedDateRange(null);
                        setSelectedDateType("created_at");
                      }}
                    >
                      <MenuItem value={"all"}>All</MenuItem>
                      {columns.map(
                        (cell) =>
                          cell.label !== "No." &&
                          cell.id !== "created_by" &&
                          cell.id !== "updated_at" &&
                          cell.id !== "created_at" &&
                          cell.id !== "logo" &&
                          cell.id !== "activate" &&
                          cell.id !== "docs" &&
                          // cell.id !== "last_step" &&
                          cell.id !== "action" && (
                            <MenuItem key={cell.id} value={cell.id}>
                              {cell.label}
                            </MenuItem>
                          )
                      )}
                      <MenuItem value={"address"}>Address</MenuItem>
                      <MenuItem value={"city"}>City</MenuItem>
                      <MenuItem value={"state"}>State</MenuItem>
                      <MenuItem value={"zip"}>Zip</MenuItem>
                      <MenuItem value={"ofc_address"}>Office Address</MenuItem>
                      <MenuItem value={"ofc_city"}>Office City</MenuItem>
                      <MenuItem value={"ofc_state"}>Office State</MenuItem>
                      <MenuItem value={"ofc_zip"}>Office Zip</MenuItem>
                      <MenuItem value={"ofc_suite"}>Office Suite</MenuItem>
                      <MenuItem value={"ofc_phone"}>Office Number</MenuItem>
                      <MenuItem value={"last_step"}>Last Step</MenuItem>
                      <MenuItem value={"created_at"}>Created At</MenuItem>
                      <MenuItem value={"updated_at"}>Modified</MenuItem>
                    </TextField>
                  </FormControl>
                </Grid>
                {selectedType !== "phone" &&
                  selectedType !== "ofc_phone" &&
                  selectedType !== "is_active" &&
                  selectedType !== "last_step" &&
                  selectedType !== "created_at" &&
                  selectedType !== "updated_at" &&
                  selectedType !== "service_provider_type" && (
                    <Grid item xs={12} sm={12} md={4} lg={2}>
                      <FormControl
                        className="filter-search w-100"
                        variant="outlined"
                      >
                        <InputLabel>Enter search term</InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          endAdornment={
                            <InputAdornment position="end">
                              <Search />
                            </InputAdornment>
                          }
                          label="Enter search term"
                          value={searchedTerm}
                          onChange={(e) => setSearchedTerm(e.target.value)}
                        />
                      </FormControl>
                    </Grid>
                  )}

                {(selectedType === "is_active" || selectedType === "all") && (
                  <Grid item xs={12} sm={12} md={4} lg={2}>
                    <FormControl className="filter-select-one w-100">
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="Status"
                        value={selectedStatus}
                        onChange={(e) => setSelectedStatus(e.target.value)}
                      >
                        <MenuItem value={"active"}>Active</MenuItem>
                        <MenuItem value={"pending"}>Pending</MenuItem>
                        <MenuItem value={"inactive"}>Inactive</MenuItem>
                        {/* <MenuItem value="deleted">Deleted</MenuItem> */}
                      </TextField>
                    </FormControl>
                  </Grid>
                )}

                {(selectedType === "service_provider_type" ||
                  selectedType === "all") && (
                  <Grid item xs={12} sm={12} md={4} lg={2}>
                    <FormControl className="filter-select-one w-100">
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="Providers Type"
                        value={selectedProviderType}
                        onChange={(e) => {
                          console.log(
                            "selected service_provider_type==>",
                            e.target.value
                          );
                          setSelectedProviderType(e.target.value);
                        }}
                      >
                        {professionalsList?.data?.map((val, key) => (
                          <MenuItem key={key} value={val.id}>
                            {val.type}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                )}

                {(selectedType === "phone" || selectedType === "ofc_phone") && (
                  <Grid item>
                    <Box className={`phone-number-input bg-white filter`}>
                      <PhoneInput
                        className="bg-white"
                        international={false}
                        placeholder="Phone Number"
                        value={searchedTerm}
                        countries={["US", "IN"]}
                        defaultCountry={COUNTRY_CODE === "+91" ? "IN" : "US"}
                        countryOptionsOrder={["US", "IN"]}
                        addInternationalOption={false}
                        onChange={(event) => {
                          setSearchedTerm(event);
                        }}
                      />
                    </Box>
                  </Grid>
                )}

                {(selectedType === "last_step" || selectedType === "all") && (
                  <Grid item xs={12} sm={12} md={4} lg={2}>
                    <FormControl className="filter-select-one w-100">
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="Last Step"
                        value={jsonLastStep}
                        onChange={(e) => {
                          setJsonLastStep(e.target.value);
                        }}
                      >
                        {servicerProvidersjsonLastStepOptions.map(
                          (data, key) => (
                            <MenuItem value={data.value}>{data.label}</MenuItem>
                          )
                        )}
                      </TextField>
                    </FormControl>
                  </Grid>
                )}
                {selectedType === "all" && (
                  <Grid item xs={12} sm={12} md={4} lg={2}>
                    <FormControl className="filter-select-one w-100">
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="Date type"
                        value={selectedDateType}
                        onChange={(e) => {
                          setSelectedDateType(e.target.value);
                        }}
                      >
                        {[
                          { label: "Created At", value: "created_at" },
                          { label: "Updated At", value: "updated_at" },
                        ].map((data, key) => (
                          <MenuItem value={data.value}>{data.label}</MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                )}
                {(selectedType === "all" ||
                  selectedType === "created_at" ||
                  selectedType === "updated_at") && (
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <FormControl className="filter-search w-100">
                      <CustomDateRangePicker
                        rangeValue={selectedDateRange}
                        onChange={(values) => {
                          setSelectedDateRange(values);
                        }}
                        onClear={() => {
                          setSelectedDateRange(null);
                        }}
                      />
                    </FormControl>
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={2}
                  lg={2}
                  className="d-flex justify-content-center align-items-center"
                >
                  <FormControl className="w-100">
                    <Button
                      type="submit"
                      onClick={handleFilterList}
                      sx={mobileFilterResultStyle}
                    >
                      Filter Results
                    </Button>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={2}
                  lg={2}
                  className="d-flex justify-content-center align-items-center"
                >
                  <Button
                    type="reset"
                    onClick={resetAllFilterOptions}
                    sx={mobileFilterResetBtnStyle}
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </Box>

            {/* for desktop view */}
            <Card className="d-none d-md-block filter-options-carddiv">
              <Grid container className="filter-options-box">
                <Grid item>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <div className="search-text">Search By</div>
                    <FormControl className="filter-select-one">
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="All Types"
                        value={selectedType}
                        onChange={(e) => {
                          console.log("selected values==>", e.target.value);
                          setSelectedType(e.target.value);
                          setSearchedTerm("");
                          setSelectedStatus("");
                          setSelectedProviderType("");
                          setJsonLastStep("");
                          setSelectedDateRange(null);
                          setSelectedDateType("created_at");
                        }}
                      >
                        <MenuItem value={"all"}>All</MenuItem>
                        {columns.map(
                          (cell) =>
                            cell.label !== "No." &&
                            cell.id !== "created_by" &&
                            cell.id !== "updated_at" &&
                            cell.id !== "logo" &&
                            cell.id !== "created_at" &&
                            cell.id !== "activate" &&
                            cell.id !== "docs" &&
                            // cell.id !== "last_step" &&
                            cell.id !== "action" && (
                              <MenuItem key={cell.id} value={cell.id}>
                                {cell.label}
                              </MenuItem>
                            )
                        )}
                        <MenuItem value={"address"}>Address</MenuItem>
                        <MenuItem value={"city"}>City</MenuItem>
                        <MenuItem value={"state"}>State</MenuItem>
                        <MenuItem value={"zip"}>Zip</MenuItem>
                        <MenuItem value={"ofc_address"}>
                          Office Address
                        </MenuItem>
                        <MenuItem value={"ofc_city"}>Office City</MenuItem>
                        <MenuItem value={"ofc_state"}>Office State</MenuItem>
                        <MenuItem value={"ofc_suite"}>Office Suite</MenuItem>
                        <MenuItem value={"ofc_phone"}>Office Number</MenuItem>
                        <MenuItem value={"ofc_zip"}>Office Zip</MenuItem>
                        <MenuItem value={"last_step"}>Last Step</MenuItem>
                        <MenuItem value={"created_at"}>Created At</MenuItem>
                        <MenuItem value={"updated_at"}>Modified</MenuItem>
                      </TextField>
                    </FormControl>
                  </Stack>
                </Grid>
                {selectedType !== "phone" &&
                  selectedType !== "ofc_phone" &&
                  selectedType !== "is_active" &&
                  selectedType !== "last_step" &&
                  selectedType !== "created_at" &&
                  selectedType !== "updated_at" &&
                  selectedType !== "service_provider_type" && (
                    <Grid item>
                      <FormControl className="filter-search-one">
                        <InputLabel>Enter search term</InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          endAdornment={
                            <InputAdornment position="end">
                              <Search />
                            </InputAdornment>
                          }
                          label="Enter search term"
                          value={searchedTerm}
                          onChange={(e) => setSearchedTerm(e.target.value)}
                        />
                      </FormControl>
                    </Grid>
                  )}

                {(selectedType === "is_active" || selectedType === "all") && (
                  <Grid item>
                    <FormControl className="filter-select-one">
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="Status"
                        value={selectedStatus}
                        onChange={(e) => setSelectedStatus(e.target.value)}
                      >
                        <MenuItem value={"active"}>Active</MenuItem>
                        <MenuItem value={"pending"}>Pending</MenuItem>
                        <MenuItem value={"inactive"}>Inactive</MenuItem>
                        {/* <MenuItem value="deleted">Deleted</MenuItem> */}
                      </TextField>
                    </FormControl>
                  </Grid>
                )}

                {(selectedType === "service_provider_type" ||
                  selectedType === "all") && (
                  <Grid item>
                    <FormControl className="filter-select-one">
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="Providers Type"
                        value={selectedProviderType}
                        onChange={(e) => {
                          console.log(
                            "selected service_provider_type==>",
                            e.target.value
                          );
                          setSelectedProviderType(e.target.value);
                        }}
                      >
                        {professionalsList?.data?.map((val, key) => (
                          <MenuItem key={key} value={val.id}>
                            {val.type}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                )}

                {(selectedType === "phone" || selectedType === "ofc_phone") && (
                  <Grid item>
                    <Box className={`phone-number-input bg-white filter`}>
                      <PhoneInput
                        className="bg-white"
                        international={false}
                        placeholder="Phone Number"
                        value={searchedTerm}
                        countries={["US", "IN"]}
                        defaultCountry={COUNTRY_CODE === "+91" ? "IN" : "US"}
                        countryOptionsOrder={["US", "IN"]}
                        addInternationalOption={false}
                        onChange={(event) => {
                          setSearchedTerm(event);
                        }}
                      />
                    </Box>
                  </Grid>
                )}

                {(selectedType === "last_step" || selectedType === "all") && (
                  <Grid item>
                    <FormControl className="filter-select-one">
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="Last Step"
                        value={jsonLastStep}
                        onChange={(e) => {
                          setJsonLastStep(e.target.value);
                        }}
                      >
                        {servicerProvidersjsonLastStepOptions.map(
                          (data, key) => (
                            <MenuItem value={data.value}>{data.label}</MenuItem>
                          )
                        )}
                      </TextField>
                    </FormControl>
                  </Grid>
                )}
                {selectedType === "all" && (
                  <Grid item>
                    <FormControl className="filter-select-one">
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="Date type"
                        value={selectedDateType}
                        onChange={(e) => {
                          setSelectedDateType(e.target.value);
                        }}
                      >
                        {[
                          { label: "Created At", value: "created_at" },
                          { label: "Updated At", value: "updated_at" },
                        ].map((data, key) => (
                          <MenuItem value={data.value}>{data.label}</MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                )}
                {(selectedType === "all" ||
                  selectedType === "created_at" ||
                  selectedType === "updated_at") && (
                  <Grid item>
                    <FormControl className="filter-search">
                      <CustomDateRangePicker
                        rangeValue={selectedDateRange}
                        onChange={(values) => {
                          setSelectedDateRange(values);
                        }}
                        onClear={() => {
                          setSelectedDateRange(null);
                        }}
                      />
                    </FormControl>
                  </Grid>
                )}
                <Grid item>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <FormControl>
                      <Button
                        className="filter-button"
                        onClick={handleFilterList}
                      >
                        Filter Results
                      </Button>
                    </FormControl>
                    <Button
                      className="reset-filters"
                      onClick={resetAllFilterOptions}
                    >
                      Reset
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Card>
          </>
        )}
      </Box>

      {/* Property Listing Table */}
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              stickyHeader
            >
              <TableHeaderForSorting
                columns={columns}
                handleSorting={handleSorting}
                directionFlow={directionFlow}
                setSelectedColumn={setSelectedColumn}
                selectedColumn={selectedColumn}
              />
              <TableBody>
                {_DATA?.map((row, index) => {
                  return (
                    <>
                      <Row
                        key={(page - 1) * 10 + index + 1}
                        row={row}
                        handleEditButton={handleEditButton}
                        handleDeleteButton={handleDeleteButton}
                        page={page}
                        index={index}
                        handleCollapsibleRow={handleCollapsibleRow}
                        isCollapsible={
                          selectedCollapsibleRow?.id === row?.id &&
                          isCollapsibleRow
                        }
                        getProviderActivate={getProviderActivate}
                        handleMenuClick={handleMenuClick}
                      />
                    </>
                  );
                })}
              </TableBody>
            </Table>
            {!rowsData?.length > 0 && (
              <Typography className="p-5 text-center" variant="h6">
                No Data Found.
              </Typography>
            )}
          </TableContainer>
        </Paper>

        <Menu
          key="edit"
          anchorEl={anchorEl}
          id="settings-menu"
          open={open}
          onClose={handleMenuClose}
          onClick={handleMenuClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.1))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
            },
          }}
          transformOrigin={{
            horizontal: "right",
            vertical: "top",
          }}
          anchorOrigin={{
            horizontal: "right",
            vertical: "bottom",
          }}
        >
          <MenuItem
            className="setting-menu-item"
            onClick={(e) => {
              e.stopPropagation();
              dispatch(seviceProvidersAction.clearData());
              handleEditButton(selectedCollapsibleRow);
            }}
          >
            <Box className="setting-menu-box">
              <ModeOutlinedIcon />

              <Typography className="setting-menu-text">Edit</Typography>
            </Box>
          </MenuItem>
          <MenuItem
            className="setting-menu-item"
            onClick={(e) => {
              dispatch(seviceProvidersAction.clearData());
              handleDeleteButton(selectedCollapsibleRow);
            }}
          >
            <Box className="setting-menu-box">
              <DeleteOutlineIcon />
              <Typography className="setting-menu-text">Delete</Typography>
            </Box>
          </MenuItem>
          {/* {!selectedCollapsibleRow?.service_agreement ? ( */}
          <MenuItem
            className="setting-menu-item"
            onClick={(e) => {
              setIsUploadLogoModalOpen(true);
            }}
          >
            <Box className="setting-menu-box">
              <AddToPhotosOutlinedIcon />
              <Typography className="setting-menu-text">Upload Logo</Typography>
            </Box>
          </MenuItem>
          <MenuItem
            className="setting-menu-item"
            onClick={(e) => {
              setIsUploadAgreementModalOpen(true);
            }}
          >
            <Box className="setting-menu-box">
              <UploadFileOutlinedIcon />
              <Typography className="setting-menu-text">
                Upload Agreement
              </Typography>
            </Box>
          </MenuItem>
          <MenuItem
            className="setting-menu-item"
            onClick={(e) => {
              setIsHistoryAgreementModalOpen(true);
            }}
          >
            <Box className="setting-menu-box">
              <HistoryOutlinedIcon />
              <Typography className="setting-menu-text">History</Typography>
            </Box>
          </MenuItem>
          {/* ) : null} */}
          {selectedCollapsibleRow?.json_last_step?.step_key ===
          "SEND_WELCOME_EMAIL" ? (
            selectedCollapsibleRow?.status === "inactive" ? (
              <Tooltip
                title="Activate"
                TransitionComponent={Zoom}
                placement="top"
                arrow
              >
                <MenuItem
                  className="setting-menu-item"
                  onClick={(e) => {
                    setActionPerformed("activate");
                    setIsActivateDeactivateServiceProviderModalOpen(true);
                  }}
                >
                  <Box className="setting-menu-box">
                    <CheckCircleOutlineOutlinedIcon />
                    <Typography className="setting-menu-text">
                      Mark as Active
                    </Typography>
                  </Box>
                </MenuItem>
              </Tooltip>
            ) : (
              <Tooltip
                title="Inactivate"
                TransitionComponent={Zoom}
                placement="top"
                arrow
              >
                <MenuItem
                  className="setting-menu-item"
                  onClick={(e) => {
                    setActionPerformed("deactivate");
                    setIsActivateDeactivateServiceProviderModalOpen(true);
                  }}
                >
                  <Box className="setting-menu-box">
                    <CloseOutlinedIcon />
                    <Typography className="setting-menu-text">
                      Mark as Inactive
                    </Typography>
                  </Box>
                </MenuItem>
              </Tooltip>
            )
          ) : null}
        </Menu>

        {providersCount > PER_PAGE && (
          <Box id="pagination-container">
            <CustomPagination
              count={count}
              page={page}
              handlePageChange={pageChangeHandler}
            />
          </Box>
        )}

        {/* EditServiceProvide */}
        {isEditServiceProviderModalOpen && (
          <EditServiceProvide
            selectedItem={selectedItem}
            open={isEditServiceProviderModalOpen}
            handleClose={() => setIsEditServiceProviderModalOpen(false)}
            providerID={providerID}
            payload={payload}
          />
        )}

        {isHistoryAgreementModalOpen && (
          <DialogBox
            fullScreenModal={false}
            width={"md"}
            contentClassName={"bg-white"}
            title={"Service Agreement History"}
            openPopup={isHistoryAgreementModalOpen}
            onClose={() => {
              setIsHistoryAgreementModalOpen(false);
            }}
            content={
              <HistoryServiceAgreements
                selectedItem={selectedItem}
                selectedCollapsibleRow={selectedCollapsibleRow}
              />
            }
          />
        )}

        {/* Delete Service provider */}
        {isDeleteServiceProviderModalOpen && (
          <DeleteModal
            open={isDeleteServiceProviderModalOpen}
            title="Delete Service Provider"
            item="Service Provider"
            deleteLoader={actionLoader}
            handleClose={handleCloseDeleteModal}
            handleDeleteBtn={handleDeleteServiceProvider}
            fetchedData={deleteServiceProvidersRes}
            isDeleteSuccess={deleteServiceProvidersRes?.success}
            successMessage={deleteServiceProvidersRes?.message}
            errorMessage={deleteServiceProvidersRes?.data?.message}
            callListingAPI={
              // callListAPI
              () => {
                if (
                  latestData?.data?.length === 1 &&
                  providersCount > PER_PAGE &&
                  isDeleteServiceProviderModalOpen
                ) {
                  setPage(page - 1);
                  setPayload((prevState) => ({
                    ...prevState,
                    limit: PER_PAGE,
                    offset: (page - 2) * PER_PAGE,
                  }));
                } else {
                  callListAPI();
                }
              }
            }
            footer={false}
          />
        )}

        {/* Activate service provider */}
        {alertMsg && getObjLength(activateServiceProvidersRes) && (
          <>
            {getObjLength(activateServiceProvidersRes) &&
            activateServiceProvidersRes?.success ? (
              <AlertDialog
                type="Success"
                openPopup={alertMsg}
                onClose={true}
                onCloseFun={() => {
                  onSuccess();
                }}
                mesage={successMsg}
                footer={false}
              />
            ) : (
              <AlertDialog
                type="Error"
                openPopup={alertMsg}
                onClose={true}
                onCloseFun={() => {
                  setAlertMsg(false);
                  dispatch(seviceProvidersAction.clearData({}));
                }}
                mesage={errMsg}
                footer={false}
              />
            )}
          </>
        )}

        {/* Active/Inactive Alert */}
        {isActivateDeactivateServiceProviderModalOpen ? (
          <AlertDialog
            type={"Warning"}
            openPopup={isActivateDeactivateServiceProviderModalOpen}
            onClose={true}
            onCloseFun={() => {
              setActionPerformed("");
              setIsActivateDeactivateServiceProviderModalOpen(false);
            }}
            mesage={`Are you sure you want to ${
              actionPerformed === "activate"
                ? "activate the Service providers"
                : "inactivate the Service providers"
            }?`}
            buttons={
              <>
                <Stack direction="row" justifyContent="center" spacing={2}>
                  <Button
                    className="popup_Btn other_popup_btn"
                    onClick={() => {
                      setActionPerformed("");
                      setIsActivateDeactivateServiceProviderModalOpen(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="popup_Btn success_popup_btn"
                    onClick={() => {
                      handleActivateDeactivateServiceProvider(
                        selectedCollapsibleRow
                      );
                    }}
                  >
                    Okay
                  </Button>
                </Stack>
              </>
            }
            footer={false}
            loader={actionLoader}
          />
        ) : null}

        {/* activate deactivate professional success error modal */}
        {getObjLength(activateDeactivateServiceProvider) && (
          <AlertDialog
            type={
              activateDeactivateServiceProvider?.success ? "Success" : "Error"
            }
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              if (activateDeactivateServiceProvider?.success) {
                setActionPerformed("");
                setIsActivateDeactivateServiceProviderModalOpen(false);
                callListAPI();
              }
              dispatch(
                seviceProvidersAction.fetchActivateDeactivateServiceProvider({})
              );
            }}
            mesage={
              activateDeactivateServiceProvider?.success
                ? activateDeactivateServiceProvider?.message
                : activateDeactivateServiceProvider?.data?.message
            }
            footer={false}
          />
        )}
      </Box>
      {/* Upload user agreement modal */}
      {isUploadAgreementModalOpen ? (
        <AlertDialog
          type={
            !getObjLength(UploadedServiceAgreementDetails) &&
            "Upload Service Agreement"
          }
          openPopup={isUploadAgreementModalOpen}
          onClose={!getObjLength(UploadedServiceAgreementDetails) && true}
          onCloseFun={() => {
            setUploadedServiceAgreement(null);
            setErrorMessage("");
            setIsUploadAgreementModalOpen(false);
          }}
          mesage={
            <>
              {!getObjLength(UploadedServiceAgreementDetails) && (
                <span
                  className={`${
                    uploadedServiceAgreement
                      ? "d-flex justify-content-center align-items-center flex-column rounded py-4"
                      : ""
                  }`}
                  style={{
                    backgroundColor: "#F6F9FC",
                    height: "100%",
                    border: uploadedServiceAgreement ? "1px solid #E2E8EB" : "",
                    gap: uploadedServiceAgreement ? "10px" : "",
                  }}
                >
                  <>
                    {/* This condition will be true if servicer selects any file which type is either .pdf or .doc/.docx */}
                    {uploadedServiceAgreement ? (
                      <>
                        {/* This is displayed for the file type pdf */}
                        {uploadedServiceAgreement?.type ===
                        "application/pdf" ? (
                          <>
                            <span
                              className="position-relative p-2 bg-white rounded"
                              sx={{ backgroundColor: "#F6F9FC" }}
                              onClick={() => {
                                window.open(
                                  createFileUrl(uploadedServiceAgreement),
                                  "_blank"
                                );
                              }}
                            >
                              <img
                                src={pdfPlaceholder}
                                alt="Document Preview"
                                style={{
                                  cursor: "pointer",
                                }}
                              />
                              <span
                                style={closeDeleteIconStyle}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setUploadedServiceAgreement("");
                                }}
                              >
                                <CloseOutlinedIcon />
                              </span>
                            </span>
                            <Typography className="mw-100 m-0">
                              <CustomLinkWrapper
                                link={
                                  uploadedServiceAgreement
                                    ? createFileUrl(uploadedServiceAgreement)
                                    : "#"
                                }
                                title={uploadedServiceAgreement?.path}
                                key={uploadedServiceAgreement?.path}
                                classes="preview-url"
                              />
                            </Typography>
                          </>
                        ) : uploadedServiceAgreement?.type ===
                            "application/msword" ||
                          uploadedServiceAgreement?.type ===
                            "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                          <>
                            {/* This will display for the file type document */}
                            <span
                              className="position-relative p-2 bg-white rounded"
                              sx={{ backgroundColor: "#F6F9FC" }}
                              onClick={() => {
                                window.open(
                                  createFileUrl(uploadedServiceAgreement),
                                  "_blank"
                                );
                              }}
                            >
                              <img
                                src={wordDocPlaceholder}
                                alt="Document Preview"
                                style={{
                                  cursor: "pointer",
                                }}
                              />
                              <span
                                style={closeDeleteIconStyle}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setUploadedServiceAgreement("");
                                }}
                              >
                                <CloseOutlinedIcon />
                              </span>
                            </span>
                            <Typography className="mw-100 m-0">
                              <CustomLinkWrapper
                                link={
                                  uploadedServiceAgreement
                                    ? createFileUrl(uploadedServiceAgreement)
                                    : "#"
                                }
                                title={uploadedServiceAgreement?.path}
                                key={uploadedServiceAgreement?.path}
                                classes="preview-url"
                              />
                            </Typography>
                          </>
                        ) : (
                          <>
                            {/* It will show the selected file if servicer selects the file which is neither in the pdf nor in the doc category */}
                            <Box
                              className="preview-file bg-white"
                              padding="3px 15px"
                              mb={0.9}
                            >
                              <CustomLinkWrapper
                                link={
                                  uploadedServiceAgreement
                                    ? createFileUrl(uploadedServiceAgreement)
                                    : "#"
                                }
                                title={uploadedServiceAgreement?.path}
                                key={uploadedServiceAgreement?.path}
                                classes="preview-url"
                              />
                              <span
                                className="cursor-pointer"
                                onClick={() => {
                                  setUploadedServiceAgreement("");
                                }}
                              >
                                <CloseOutlinedIcon />
                              </span>
                            </Box>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {/* when there is no file selected then it will display the file upload field */}
                        <span className="servicer-signup-form">
                          <FileUpload
                            name="upload_service_agreement"
                            accept={{
                              "application/pdf": [".pdf"],
                              "application/msword": [".doc"],
                              "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                                [".docx"],
                            }}
                            multiple={false}
                            onFileUpload={async (file) => {
                              // onChange(file);
                              if (file?.length > 0) {
                                setUploadedServiceAgreement(file[0]);
                                setErrorMessage("");
                              }
                            }}
                            dragDropText={`Drag and drop Service Agreement or `}
                            greenText="Upload a Service Agreement"
                            subText="Only .pdf, .doc, .docx files are allowed"
                          />
                        </span>
                        <FormHelperText>
                          {errorMessage?.length > 0 && (
                            <Typography
                              className="text-danger"
                              sx={{ fontSize: "0.8rem" }}
                            >
                              {errorMessage}
                            </Typography>
                          )}
                        </FormHelperText>
                      </>
                    )}
                  </>
                </span>
              )}
              {getObjLength(UploadedServiceAgreementDetails) && (
                <PlainAlertMessage
                  type={
                    UploadedServiceAgreementDetails?.success
                      ? "Success"
                      : "Error"
                  }
                  openPopup={true}
                  onClose={true}
                  onCloseFun={() => {
                    if (UploadedServiceAgreementDetails?.success) {
                      setIsUploadAgreementModalOpen(false);
                      setUploadedServiceAgreement("");
                      callListAPI();
                    }
                    dispatch(
                      seviceProvidersAction.setUploadServiceAgreementDetails({})
                    );
                  }}
                  message={
                    UploadedServiceAgreementDetails?.success
                      ? UploadedServiceAgreementDetails?.message
                      : UploadedServiceAgreementDetails?.data?.message
                  }
                  footer={false}
                />
              )}
            </>
          }
          buttons={
            !getObjLength(UploadedServiceAgreementDetails) ? (
              <>
                <Stack direction="row" justifyContent="center" spacing={2}>
                  <Button
                    className="popup_Btn other_popup_btn"
                    onClick={() => {
                      setIsUploadAgreementModalOpen(false);
                      setUploadedServiceAgreement("");
                      setErrorMessage("");
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="popup_Btn success_popup_btn"
                    onClick={() => {
                      if (uploadedServiceAgreement) {
                        handleUploadServiceAgreement();
                      } else {
                        setErrorMessage("Please select the Document");
                      }
                    }}
                  >
                    Upload
                  </Button>
                </Stack>
              </>
            ) : (
              <></>
            )
          }
          footer={false}
          loader={serviceAgreementLoader}
        />
      ) : null}

      {/* uploaded service agreement success error modal */}
      {/* {getObjLength(UploadedServiceAgreementDetails) && (
        <AlertDialog
          type={UploadedServiceAgreementDetails?.success ? "Success" : "Error"}
          openPopup={true}
          onClose={true}
          onCloseFun={() => {
            if (UploadedServiceAgreementDetails?.success) {
              setIsUploadAgreementModalOpen(false);
              setUploadedServiceAgreement("");
              callListAPI();
            }
            dispatch(
              seviceProvidersAction.setUploadServiceAgreementDetails({})
            );
          }}
          mesage={
            UploadedServiceAgreementDetails?.success
              ? UploadedServiceAgreementDetails?.message
              : UploadedServiceAgreementDetails?.data?.message
          }
          footer={false}
        />
      )} */}

      {/* Upload Logo Modal */}
      {isUploadLogoModalOpen ? (
        <AlertDialog
          type={
            !getObjLength(uploadedCompanyLogoDetails) && "Upload Company Logo"
          }
          openPopup={isUploadLogoModalOpen}
          onClose={!getObjLength(uploadedCompanyLogoDetails) && true}
          onCloseFun={() => {
            setIsUploadLogoModalOpen(false);
            setCroppedImage(null);
            setSelectedFile(null);
            setErrorMessage("");
          }}
          mesage={
            <>
              {!getObjLength(uploadedCompanyLogoDetails) && (
                <span
                  className={`${
                    uploadedServiceAgreement
                      ? "d-flex justify-content-center align-items-center flex-column rounded py-4"
                      : ""
                  }`}
                  style={{
                    backgroundColor: "#F6F9FC",
                    height: "100%",
                    border: uploadedServiceAgreement ? "1px solid #E2E8EB" : "",
                    gap: uploadedServiceAgreement ? "10px" : "",
                  }}
                >
                  {!croppedImage ? (
                    selectedFile ? (
                      <>
                        {/* When service provider selects the image then this cropper will be displayed */}
                        <Cropper
                          ref={cropperRef}
                          style={{ height: "280px", width: "100%" }}
                          zoomTo={0}
                          initialAspectRatio={1}
                          src={selectedFile}
                          viewMode={1}
                          minCropBoxHeight={10}
                          minCropBoxWidth={10}
                          background={false}
                          responsive={true}
                          autoCropArea={1}
                          checkOrientation={false}
                          guides={false}
                        />
                        <Box className="d-flex justify-content-start" mt={2}>
                          <Button
                            onClick={getCropData}
                            variant="contained"
                            className="success-btn"
                            // color="primary"
                          >
                            Crop Logo
                          </Button>
                        </Box>
                        <FormHelperText>
                          {errorMessage?.length > 0 && (
                            <Typography
                              className="text-danger"
                              sx={{ fontSize: "0.8rem" }}
                            >
                              {errorMessage}
                            </Typography>
                          )}
                        </FormHelperText>
                      </>
                    ) : (
                      <>
                        {/* Initially there is neither cropped image nor selected image so upload image field will show */}
                        <span className="servicer-signup-form">
                          <FileUpload
                            name="upload_doc"
                            accept={{
                              "image/png": [".png", ".jpeg", ".jpg"],
                            }}
                            multiple={false}
                            onFileUpload={async (file) => {
                              onChange(file);
                            }}
                            dragDropText={`Drag and drop or `}
                            greenText="Upload a Logo"
                            subText="Only .png, .jpeg, .jpg files are allowed"
                          />
                          <FormHelperText>
                            {errorMessage?.length > 0 && (
                              <Typography
                                className="text-danger"
                                sx={{ fontSize: "0.8rem" }}
                              >
                                {errorMessage}
                              </Typography>
                            )}
                          </FormHelperText>
                        </span>
                      </>
                    )
                  ) : (
                    <Stack spacing={1.5}>
                      {/* After successfully cropping it will display the cropped image and delete icon for clearing the image */}
                      {!getObjLength(uploadedCompanyLogoDetails) && (
                        <Box
                          className="position-relative p-4 rounded"
                          sx={{
                            backgroundColor: "#F6F9FC",
                            border: croppedImage ? "1px solid #E2E8EB" : "",
                          }}
                        >
                          <img
                            src={croppedImage}
                            style={{
                              minHeight: "150px",
                              maxHeight: "230px",
                            }}
                            alt="cropped"
                            id="croppedImage"
                            className="rounded"
                          />

                          <IconButton
                            sx={{
                              ...closeDeleteIconStyle,
                              color: "#FC2125",
                              fontSize: "1.4rem",
                            }}
                            onClick={() => {
                              setCroppedImage(null);
                              setSelectedFile(null);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      )}
                    </Stack>
                  )}
                </span>
              )}
              {getObjLength(uploadedCompanyLogoDetails) && (
                <PlainAlertMessage
                  type={
                    uploadedCompanyLogoDetails?.success ? "Success" : "Error"
                  }
                  openPopup={true}
                  onClose={true}
                  onCloseFun={() => {
                    if (uploadedCompanyLogoDetails?.success) {
                      setIsUploadLogoModalOpen(false);
                      setSelectedFile(null);
                      setCroppedImage(null);
                      callListAPI();
                    }
                    dispatch(
                      seviceProvidersAction.setUploadedCompanyLogoDetails({})
                    );
                  }}
                  message={
                    uploadedCompanyLogoDetails?.success
                      ? uploadedCompanyLogoDetails?.message
                      : uploadedCompanyLogoDetails?.data?.message
                  }
                  footer={false}
                />
              )}
            </>
          }
          buttons={
            !getObjLength(uploadedCompanyLogoDetails) ? (
              <>
                <Stack direction="row" justifyContent="center" spacing={2}>
                  <Button
                    className="popup_Btn other_popup_btn"
                    onClick={() => {
                      setIsUploadLogoModalOpen(false);
                      setSelectedFile(null);
                      setCroppedImage(null);
                      setErrorMessage("");
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="popup_Btn success_popup_btn"
                    onClick={() => {
                      if (croppedImage) {
                        handleUploadingCompanyLogo();
                      } else {
                        setErrorMessage(
                          "Please select and crop the Company Logo"
                        );
                      }
                    }}
                  >
                    Upload
                  </Button>
                </Stack>
              </>
            ) : (
              <></>
            )
          }
          footer={false}
          loader={companyLogoLoader}
        />
      ) : null}

      {/* uploaded company logo success error modal */}
      {/* {getObjLength(uploadedCompanyLogoDetails) && (
        <AlertDialog
          type={uploadedCompanyLogoDetails?.success ? "Success" : "Error"}
          openPopup={true}
          onClose={true}
          onCloseFun={() => {
            if (uploadedCompanyLogoDetails?.success) {
              setIsUploadLogoModalOpen(false);
              setSelectedFile(null);
              setCroppedImage(null);
              callListAPI();
            }
            dispatch(seviceProvidersAction.setUploadedCompanyLogoDetails({}));
          }}
          mesage={
            uploadedCompanyLogoDetails?.success
              ? uploadedCompanyLogoDetails?.message
              : uploadedCompanyLogoDetails?.data?.message
          }
          footer={false}
        />
      )} */}
    </>
  );
};

export default ServiceProvidersTable;
