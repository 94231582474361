import React from "react";
import { useState } from "react";
import { useEffect } from "react";

import { useSelector } from "react-redux";

import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Dialog, DialogContent, DialogTitle } from "@mui/material";

import UccForm from "./UccForm";
import { Loader } from "../../common/loader";
import AlertDialog from "../../common/AlertDialog";
import { urlPatternValidation } from "../../helpers/utils";
import PlainAlertMessage from "../../common/PlainAlertMessage";

const PropertyRequestDocumentModal = ({
  open,
  handleClose,
  selectedProperty,
  requestLoader,
  isRequestSuccess,
  successMessage,
  errorMessage,
  handleRequestDocBtn,
  fetchPropertyListingData,
  isPropertyVerified = true,
}) => {
  const [isResponseModalOpen, setIsResponseModalOpen] = useState(false);
  const { propertyRequestDocument } = useSelector(
    (state) => state?.propertyLists
  );

  console.log(isRequestSuccess, "selected property", selectedProperty);

  const handleOpenResponseModal = () => {
    setIsResponseModalOpen(true);
  };
  const handleCloseResponseModal = () => {
    setIsResponseModalOpen(false);
  };

  useEffect(() => {
    if (isRequestSuccess === true || isRequestSuccess === false) {
      if (
        isRequestSuccess &&
        urlPatternValidation(propertyRequestDocument?.data?.ucc_link)
      ) {
        // console.log("success request", propertyRequestDocument);
        // const downloadURL = window.URL.createObjectURL(fileURL);
        const link = document.createElement("a");
        link.href = propertyRequestDocument?.data?.ucc_link;
        link.target = "_blank";
        // link.setAttribute("download", "Property Document");
        // document.appendChild(link);
        link.click();
      }
      handleOpenResponseModal();
    }
  }, [
    isRequestSuccess,
    propertyRequestDocument,
    propertyRequestDocument?.data?.ucc_link,
  ]);

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth={`${isPropertyVerified ? "lg" : "sm"}`}
        onClose={handleClose}
        className={`${isPropertyVerified ? "zoom-modal-wrapper" : ""}`}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {isPropertyVerified ? (
          <Box
            className="zoom-container"
            sx={{
              marginTop: "20px !important",
              marginBottom: "15px !important",
            }}
          >
            <DialogTitle id="alert-dialog-title">
              <div className="">UCC FORM</div>
              {/* <Divider /> */}
              {/* <p className="close-text" style={{right:"36px",top:"-28px"}}>Close</p> */}
              <Button
                onClick={handleClose}
                className="modal-close-btn close-text"
                style={{
                  position: "absolute",
                  right: 10,
                  top: 15,
                  background: "transparent",
                  textTransform: "none",
                }}
              >
                Close
                <CloseIcon sx={{ color: "#9CB8A6" }} />
              </Button>
            </DialogTitle>
            <DialogContent className="bg-white mb-2">
              <Box className="d-flex justify-content-center">
                <UccForm
                  selectedProperty={selectedProperty}
                  handleRequestDocBtn={handleRequestDocBtn}
                />
              </Box>
            </DialogContent>
          </Box>
        ) : (
          <PlainAlertMessage
            type="Error"
            openPopup={open}
            onClose={true}
            onCloseFun={handleClose}
            message={
              <span className="px-5">
                You cannot generate a UCC document because the required security
                question was not answered.
              </span>
            }
            footer={false}
          />
        )}
        <Loader open={requestLoader} />
      </Dialog>
      {isResponseModalOpen && (
        <AlertDialog
          type={isRequestSuccess ? "Success" : "Error"}
          openPopup={isResponseModalOpen}
          onClose={false}
          mesage={isRequestSuccess ? successMessage : errorMessage}
          footer={false}
          buttons={
            isRequestSuccess ? (
              <div className="text-center stepBtn mt-4">
                <Button
                  onClick={() => {
                    handleCloseResponseModal();
                    handleClose();
                    fetchPropertyListingData();
                  }}
                  className="next_button"
                  variant="contained"
                >
                  ok, All Good
                </Button>
              </div>
            ) : (
              <div className="text-center stepBtn mt-4">
                <Button
                  onClick={handleCloseResponseModal}
                  className="next_button"
                  variant="contained"
                >
                  ok, Close
                </Button>
              </div>
            )
          }
        />
      )}
    </>
  );
};

export default PropertyRequestDocumentModal;
