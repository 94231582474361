import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import RealStoryDesign from "../common/RealStoryDesign";
import ReadMoreText from "../common/ReadMoreText";
import { LazyLoadImage } from "react-lazy-load-image-component";
import TitleLock from "../../assets/SEO/RealEstateScam.webp";
import Slider from "react-slick";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import RealEstateImg from "../../assets/SEO/RealEstateScamResponsive.webp";
import MortgageImg from "../../assets/SEO/MortgageResponsive.webp";
import TitleInsuranceImg from "../../assets/SEO/TitleInsuranceResponsive.webp";

const Blogs = () => {
  const [isSwiping, setIsSwiping] = useState(false);

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 1000,
    cssEase: "ease-in-out", // Smooth easing transition
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enables autoplay
    autoplaySpeed: 4000, // Slide changes every 3 seconds
    pauseOnHover: true, // Pause when the user hovers over the slider
    pauseOnFocus: true, // Pause when the slider gains focus
    swipeToSlide: true, // Smooth swipe to slide
    draggable: true, // Smooth dragging
    beforeChange: () => {
      setIsSwiping(true);
    },
    afterChange: () => {
      setIsSwiping(false);
    },
    onSwipe: () => {
      setIsSwiping(false);
    },
    swipeEvent: () => {
      setIsSwiping(true);
    },
  };

  const readMoreText = (text, link) => (
    <>
      <p className="para_texts">
        {text.slice(0, 175)}
        {text.length > 175 ? "..." : ""}
      </p>
      <span
        onClick={(event) => {
          event.stopPropagation();
        }}
        //  className="read-or-hide"
      >
        <p className="read_more_blog">
          <Link
            to={link}
            //target="_blank"
          >
            {" Read More >"}
          </Link>
        </p>
      </span>
    </>
  );

  return (
    <div className="blogs-content">
      <div className="first_section_div">
        <section className="d-flex first-blog-section">
          <Container fluid className="text-white main_container">
            <Row className=" justify-content-center">
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={10}
                xl={8}
                className="header_texts"
              >
                <>
                  <h1 className="fw-700 mb-3 main-text text-center">Blogs</h1>
                </>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="blog_title learn_ep_section">
          <Container fluid className="p-0 card_top">
            <Slider {...settings}>
              <Row className="text-start blog_card">
                <Col xs={12} sm={12} md={6} className="p-0">
                  <Box className="m-0 markgroup_img">
                    <img
                      src={RealEstateImg}
                      className="h-100 w-100"
                      alt="home_Img"
                    />
                  </Box>
                </Col>
                <Col xs={12} sm={12} md={6} className="">
                  <Box className="blog_content">
                    <>
                      <Typography className="title mb-4 d-flex text-start">
                        Common Real Estate Scams and How to Handle Them
                      </Typography>

                      {readMoreText(
                        `The real estate industry can be lucrative, but it also
                          comes with its fair share of scams and frauds.
                          Homeowners should be aware of the common red flags in
                          real estate transactions to protect themselves from
                          falling victim to these schemes. Here are some of the
                          most common real estate scams and how to handle them
                          to keep your money and property safe.
                        `,
                        "/real-estate-scams"
                      )}
                    </>
                  </Box>
                </Col>
              </Row>
              <Row className="text-start blog_card">
                <Col xs={12} sm={12} md={6} className="p-0">
                  <Box className="m-0 markgroup_img">
                    <img
                      src={TitleInsuranceImg}
                      className="h-100 w-100"
                      alt="home_Img"
                    />
                  </Box>
                </Col>
                <Col xs={12} sm={12} md={6} className="">
                  <Box className="blog_content">
                    <>
                      <Typography className="title mb-4 d-flex text-start">
                        What is Title Insurance and What Does It Cover
                      </Typography>
                      {readMoreText(
                        `Title insurance is an integral part of the home-buying
                        process, providing homeowners with protection and peace
                        of mind. But what exactly does title insurance cover?
                        Knowing how title insurance works can help homeowners
                        avoid unexpected complications. Let’s explore how this
                        coverage ensures a smooth homeownership experience.`,
                        "/does-title-insurance-cover-title-theft"
                      )}
                    </>
                  </Box>
                </Col>
              </Row>
              <Row className="text-start blog_card">
                <Col xs={12} sm={12} md={6} className="p-0">
                  <Box className="m-0 markgroup_img">
                    <img
                      src={MortgageImg}
                      className="h-100 w-100"
                      alt="home_Img"
                    />
                  </Box>
                </Col>
                <Col xs={12} sm={12} md={6} className="">
                  <Box className="blog_content">
                    <>
                      <Typography className="title mb-4 d-flex text-start">
                        Can Your Home Title Be Stolen If You Have a Mortgage
                      </Typography>
                      {readMoreText(
                        `Homeownership is a significant investment and a source
                        of pride for many individuals. However, there are
                        potential risks associated with it, including the threat
                        of title theft. Homeowners must understand these risks
                        and take proactive steps to protect their most valuable
                        assets. You should be aware of home title protection and
                        the potential implications of home title theft with a
                        mortgage.`,
                        "/can-your-home-title-be-stolen-if-you-have-a-mortgage"
                      )}
                    </>
                  </Box>
                </Col>
              </Row>
            </Slider>
          </Container>
        </section>
      </div>

      <section className="story_section">
        <Container fluid className="mx-150">
          <div>
            <RealStoryDesign
              subTitle="hide"
              title="Other articles"
              IsGrid={true}
              readMoreClass="read_more_blog"
            />
          </div>
        </Container>
      </section>
    </div>
  );
};

export default Blogs;
